import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import { APPLICANTS_API } from '../../../../utils/consts'
import Position from './Position'
import * as Yup from 'yup'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: '40px 20px',
  gap: '40px'
}

const defaultJobTitles = {
  al: '',
  de: '',
  fr: '',
  it: '',
  en: ''
}

const defaultErrors = {
  al: '',
  de: '',
  fr: '',
  it: '',
  en: ''
}

const validationSchema = Yup.object().shape({
  al: Yup.string().required('Ju lutemi shenoni poziten ne Shqip'),
  de: Yup.string().required('Ju lutemi shenoni poziten ne Gjermanisht'),
  fr: Yup.string().required('Ju lutemi shenoni poziten ne Frengjisht'),
  it: Yup.string().required('Ju lutemi shenoni poziten ne Italisht'),
  en: Yup.string().required('Ju lutemi shenoni poziten ne Anglisht')
})

export const languages = { al: 'Shqip', de: 'Gjermane', fr: 'Frenge', it: 'Italine', en: 'Angleze' }

const OpenPosition = () => {
  const [jobPositions, setJobPositions] = useState([])
  const [jobTitles, setJobTitles] = useState(defaultJobTitles)
  const [error, setError] = useState(defaultErrors)
  const [open, setOpen] = useState(false)

  const getJobPositions = async () => {
    try {
      const response = await axios.get(`${APPLICANTS_API}/applicants/jobPosition`, { withCredentials: false })
      setJobPositions(response.data.jobPositions)
    } catch (error) {
      toast.error(error.response?.data?.message)
      console.log('error', error)
    }
  }

  const addNewPosition = async (e) => {
    e.preventDefault()
    const newErrors = { ...defaultErrors }

    try {
      await validationSchema.validate(jobTitles, { abortEarly: false })

      const response = await axios.post(`${APPLICANTS_API}/applicants/jobPosition`, {
        translations: jobTitles,
        job_title: jobTitles.al
      }, { withCredentials: false })

      if (response.data.success) {
        const newJobPosition = response.data.newJobTitle
        setJobPositions((prevJobPositions) => [...prevJobPositions, newJobPosition])
        toast.success('Pozita u krijua me sukses!')
      }

      setJobTitles(defaultJobTitles)
      setError(defaultErrors)
      setOpen(false)
    } catch (validationError) {
      if (validationError instanceof Yup.ValidationError) {
        validationError.inner.forEach((err) => {
          const key = err.path
          newErrors[key] = err.message
        })
        setError(newErrors)
      } else {
        console.log('error', validationError)
      }
    }
  }

  const updatePosition = async (id, status) => {
    try {
      const response = await axios.put(`${APPLICANTS_API}/applicants/jobPosition?id=${id}`, {
        status
      }, { withCredentials: false })
      if (response.data.success) {
        const updatedJobPosition = response.data.jobPosition

        setJobPositions((prevJobPositions) =>
          prevJobPositions.map((position) => (position._id === updatedJobPosition._id ? updatedJobPosition : position))
        )
        toast.success('Statusi i pozites u ndryshua me sukses!')
      }
    } catch (error) {
      toast.error(error.response?.data?.message)
      console.log('error', error)
    }
  }

  const deletePosition = async (id) => {
    try {
      const response = await axios.delete(`${APPLICANTS_API}/applicants/jobPosition?id=${id}`, { withCredentials: false })
      if (response.data.success) {
        setJobPositions((prevJobPositions) => prevJobPositions.filter((position) => position._id !== id))
        toast.success('Pozita u fshij me sukses')
      }
    } catch (error) {
      toast.error(error.response?.data?.message)
      console.log('error', error)
    }
  }

  useEffect(() => {
    getJobPositions()
  }, [])

  const updateLanguageState = (e, key) => setJobTitles({ ...jobTitles, [key]: e.target.value })

  const openJobPositions = jobPositions.filter((item) => item.status)
  const closedJobPositions = jobPositions.filter((item) => !item.status)

  return (
    <div>
      <h3>Pozitat aktive</h3>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {openJobPositions.length > 0
          ? openJobPositions.map(
              (oneJob) =>
                oneJob.status && (
                  <Position job={oneJob} updatePosition={updatePosition} deletePosition={deletePosition} isActive={oneJob.status} />
                )
            )
          : 'Nuk ka te dhena'}
      </div>
      <div style={{ marginTop: '10px' }}>
        <h4>Pozitat joaktive</h4>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              maxWidth: '70%'
            }}
          >
            {closedJobPositions.length > 0
              ? closedJobPositions.map(
                  (oneJob) =>
                    !oneJob.status && (
                      <Position job={oneJob} updatePosition={updatePosition} deletePosition={deletePosition} isActive={oneJob.status} />
                    )
                )
              : 'Nuk ka te dhena'}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button color='info' style={{ marginLeft: '10px' }} onClick={() => setOpen(true)}>
              Shto pozitën e re
            </Button>
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <form style={style} onSubmit={addNewPosition}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Sheno emrin e pozites ne te gjitha gjuhet e kerkuara.
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                  {Object.keys(languages).map((key) => (
                    <>
                      <TextField
                        error={error[key] !== ''}
                        key={key}
                        id='outlined-basic'
                        variant='outlined'
                        label={`${languages[key]}*`}
                        value={jobTitles[key]}
                        sx={{
                          fieldset: {
                            borderBottom: '1px solid rgba(0,0,0,0.23)'
                          }
                        }}
                        onChange={(e) => updateLanguageState(e, key)}
                      />
                      <p style={{ color: 'red' }}>{error[key]}</p>
                    </>
                  ))}
                </Box>
                <Button color='info' style={{ marginLeft: '10px' }} type='submit'>
                  Shto pozitën e re
                </Button>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenPosition
