import axios from 'axios'
import { saveAs } from 'file-saver'
import { inject } from 'mobx-react'
import moment from 'moment'
import pubsub from 'pubsub-js'
import React, { Fragment } from 'react'
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { getAwsDocuments, getAwsImage } from '../../utils/awsPaths'
import { ChangePasswordDialog } from '../Common/DialogBox.js'
import FormValidator from '../Forms/FormValidator.js'
import ContentWrapper from '../Layout/ContentWrapper'
import Loader from '../Layout/Loader'
import ResignInterviewModal from './resignInterview/resignInterviewModal'
import ResignInterviewCard from './resignInterview/resignInterviewCard'

var mimetype2fa = require('mimetype-to-fontawesome')({ prefix: 'fa-' })

const prettyBytes = require('pretty-bytes')
@inject('userStore')
class Wages extends React.Component {
  _isMounted = false
  constructor() {
    super()
    this.state = {
      formProfile: {
        city: '',
        phone: '',
        address: '',
        contactEmergency: ''
      },
      user_data: false,
      cities: [],
      departments: [],
      languages: [],
      userWarnings: [],
      files: [],
      dropdownOpen: [],
      loadingFile: {},
      modal: false,
      data: '',
      resignForm: {
        resign_reason: '',
        is_active: false
      },
      resingProcess: false
    }

    this._getData = this._getData.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleSwal = this.toggleSwal.bind(this)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleSwal() {
    Swal.fire({
      title: 'A jeni i sigurt?',
      type: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`resigns/${this.props.userStore.user_id}`, {
            cancelToken: this.axiosCancelSource.token
          })
          .then((res) => {
            this.setState({
              resignForm: {
                ...this.state.resignForm,
                is_active: false
              }
            })
          })
          .catch((error) => {
            console.log('error', error)
          })
      }
    })
  }

  _getData() {
    axios
      .get(`users/${this.props.userStore.user_id}`)
      .then((userData) => {
        if (userData.data.success) {
          axios
            .get('departments', {
              projectId: userData.data.data.project && userData.data.data.project._id ? userData.data.data.project._id : null
            })
            .then((departmentReponse) => {
              if (departmentReponse.data.success) {
                this.setState({
                  departments: departmentReponse.data.data.reduce((t, e) => {
                    return { ...t, [e._id]: e.name }
                  }, {})
                })
              }
            })

          axios
            .get('languages', {
              projectId: userData.data.data.project && userData.data.data.project._id ? userData.data.data.project._id : null
            })
            .then((languageResponse) => {
              if (languageResponse.data.success) {
                this.setState({
                  languages: languageResponse.data.data.reduce((t, e) => {
                    return { ...t, [e._id]: e.name }
                  }, {})
                })
              }
            })
          this.setState({
            user_data: userData.data.data,
            formProfile: {
              ...this.state.formProfile,
              city: userData.data.data.city && userData.data.data.city._id,
              phone: userData.data.data.phone_no && userData.data.data.phone_no,
              speeex_badge: userData.data.data.speeex_badge && userData.data.data.speeex_badge,
              address: userData.data.data.address && userData.data.data.address,
              contactEmergency: userData.data.data.emergency_contact && userData.data.data.emergency_contact
            }
          })
        } else {
          this.props.history.push('/')
        }
      })
      .catch((e) => {
        this.props.history.push('/')
      })

    axios
      .get(`cities`)
      .then((cityData) => {
        if (cityData.data.success) {
          this.setState({
            cities: cityData.data.data
          })
        }
      })
      .catch((e) => {})

    axios
      .get(`resigns/${this.props.userStore.user_id}`)
      .then(({ data }) => {
        this.setState({
          resingProcess: !!data?.data
        })
      })
      .catch((e) => {})
  }

  async _getDataFiles() {
    try {
      const response = await axios.get(getAwsDocuments(this.props.userStore.user_id), { withCredentials: false })

      if (response.data && response.statusText == 'OK') {
        this.setState({
          files: response.data.reverse() || []
        })
      } else {
        this.setState({})
      }
    } catch (e) {
      this.setState({})
      console.log('error', e)
    }
  }

  dropdownToggle = (index) => {
    this.setState({
      dropdownOpen: { [index]: !this.state.dropdownOpen[index] }
    })
  }

  downloadFile = (file) => {
    try {
      saveAs(getAwsImage(file.file_path), file.filename)
    } catch (e) {
      console.log('error', e)
      pubsub.publish('showNotification', {
        message: 'Gabim gjatë shkarkimit të file',
        type: 'error'
      })
    }
  }

  messageCrop(text) {
    var limit = 18
    var trimmedString = text.length > limit ? text.slice(0, limit - 3) + '...' : text
    return trimmedString
  }

  componentDidMount() {
    this.axiosCancelSource = axios.CancelToken.source()

    axios
      .get(`resigns/${this.props.userStore.user_id}`, {
        cancelToken: this.axiosCancelSource.token
      })
      .then((res) => {
        this.setState({
          resignForm: {
            ...this.state.resignForm,
            is_active: res.data.data.is_active
          }
        })
      })
      .catch((error) => {})

    this._isMounted = true
    if (this._isMounted) {
      this._getDataFiles()
      this._getData()
      this._loadWarnings()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  changePassword = (e) => {
    e.preventDefault()
    ChangePasswordDialog()
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  async _loadWarnings() {
    try {
      const response = await axios.get(`userwarnings/${this.props.userStore.user_id}`)
      if (response.data.success) {
        this.setState({
          userWarnings: response.data.data.warnings,
          loading: false
        })
      } else {
        this.setState({
          error: response.data.message,
          loading: false
        })
      }
    } catch (e) {
      this.setState({
        loading: false
      })
    }
  }

  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))
    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (!hasError) {
      const postData = {
        city: this.state[form.name].city,
        phone_no: this.state[form.name].phone,
        speeex_badge: this.state[form.name].speeex_badge,
        address: this.state[form.name].address,
        emergency_contact: this.state[form.name].contactEmergency
        // covid: this.state[form.name].covid,
        // year: this.state[form.name].year,
        // vaccinated: this.state[form.name].vaccinated,
        // passport: this.state[form.name].passport
      }

      if (postData.vaccinated != 'Doza 1' && postData.vaccinated != 'Doza 2') {
        postData.passport = ''
      }

      // if (postData.covid == 'Jo') {
      //   postData.year = ''
      // }

      axios
        .put(`users/${this.props.userStore.user_id}`, postData)
        .then((response) => {
          if (response.data.success) {
            this._getData()
            pubsub.publish('showNotification', {
              message: 'Te dhenat jan ruajtur!',
              type: 'success'
            })
          } else {
            pubsub.publish('showNotification', {
              message: response.data.message,
              type: 'error'
            })
          }
        })
        .catch((e) => {
          console.log('error', e)
          pubsub.publish('showNotification', {
            message: 'Gabim ne ruajtjen e te dhenave',
            type: 'error'
          })
        })
    }
  }

  onResignSubmit = (e) => {
    const { resign_reason, is_active } = this.state.resignForm

    e.preventDefault()
    const form = e.target
    const inputs = [...form.elements].filter((i) => {
      if (i.name) {
        return ['INPUT', 'SELECT', 'TEXTAREA', 'RANGE'].includes(i.nodeName)
      } else {
        return false
      }
    })

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (hasError) {
      pubsub.publish('showNotification', {
        message: 'Plotësoni të gjitha fushat!',
        type: 'error'
      })
    }

    if (!hasError) {
      const newResign = {
        resign_reason,
        user_id: this.props.userStore.user_id,
        is_active
      }

      //Post call
      axios
        .post('resigns', newResign)
        .then((res) => {
          if (res.data.success) {
            pubsub.publish('showNotification', {
              message: 'Të dhënat janë ruajtur!',
              type: 'success'
            })
          } else {
            pubsub.publish('showNotification', {
              message: res.data.message,
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log('error', err)
        })

      //Close Modal
      this.toggle()

      //Show other btn
      this.setState({
        resignForm: {
          ...this.state.resignForm,
          is_active: !this.state.is_active
        }
      })
    }
  }

  render() {
    if (!this.state.user_data) {
      return <Loader />
    }

    return (
      <ContentWrapper>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className='resign_modal'>
          <ModalHeader toggle={this.toggle}>Arsya e dorëheqjës</ModalHeader>
          <ModalBody>
            <form onSubmit={this.onResignSubmit} name='resignForm'>
              <Label for='resign_reason'>Ju lutem shenoni arsyen e doreheqjes:</Label>
              <Input
                type='textarea'
                name='resign_reason'
                invalid={this.hasError('resignForm', 'resign_reason', 'required')}
                onChange={this.validateOnChange}
                value={this.state.resignForm.resign_reason}
                data-validate='["required"]'
              />
              <span className='invalid-feedback'>Fusha është e nevojshme</span>
              {''}
              <div className='pt-2'>
                <Button color='danger' type='submit'>
                  Dërgo
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <div className='content-heading'>Të dhënat e profilit</div>

        <Row>
          <Col lg='4'>
            <div className='card card-default'>
              <div className='card-body text-center'>
                <div className='py-4'>
                  <img className={`img-fluid rounded-circle img-thumbnail thumb96`} src={'/img/blank_profile.png'} alt='Contact' />
                </div>
                <h3 className='m-0 text-bold'>{`${this.state.user_data.first_name} ${this.state.user_data.last_name}`}</h3>
                <div className='my-3'>
                  <p>{this.state.user_data.function && this.state.user_data.function.name}</p>
                </div>
                <div className='text-center'>
                  <button type='button' onClick={this.changePassword} className='btn btn-primary mr-3'>
                    Ndrysho fjalëkalimin
                  </button>
                  {/* {!this.state.resignForm.is_active ? (
                    <button
                      style={{ marginLeft: "10px" }}
                      type="button"
                      onClick={this.toggle}
                      className="btn btn-danger"
                    >
                      Jep dorëheqje
                    </button>
                  ) : (
                    <button
                      style={{ marginLeft: "10px" }}
                      type="button"
                      onClick={this.toggleSwal}
                      className="btn btn-success"
                    >
                      Terhiqe doreheqjen
                    </button>
                  )} */}
                </div>
              </div>
              <div className='table-responsive'>
                <table style={{ tableLayout: 'fixed' }} className='table'>
                  <tbody>
                    {this.state.user_data.first_name ? (
                      <tr>
                        <td>
                          <strong>Emri</strong>
                        </td>
                        <td>{this.state.user_data.first_name}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.last_name ? (
                      <tr>
                        <td>
                          <strong>Mbiemri</strong>
                        </td>
                        <td>{this.state.user_data.last_name}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>
                        <strong>SPEEEX Badge</strong>
                      </td>
                      <td>{this.state.user_data.speeex_badge ? this.state.user_data.speeex_badge : 'Ende i padefinuar'}</td>
                    </tr>
                    {this.state.user_data.email ? (
                      <tr>
                        <td>
                          <strong>Email</strong>
                        </td>
                        <td>{this.state.user_data.email}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>
                        <strong>Email Personal</strong>
                      </td>
                      <td>{this.state.user_data.personal_email ? this.state.user_data.personal_email : 'Ende i padefinuar'}</td>
                    </tr>
                    {this.state.user_data.phone_no ? (
                      <tr>
                        <td>
                          <strong>Telefoni</strong>
                        </td>
                        <td>{this.state.user_data.phone_no}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.marital_status ? (
                      <tr>
                        <td>
                          <strong>Statusi martësor</strong>
                        </td>
                        <td>{this.state.user_data.marital_status}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.emergency_contact ? (
                      <tr>
                        <td>
                          <strong>Kontakti emergjent</strong>
                        </td>
                        <td>{this.state.user_data.emergency_contact}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.identity_card ? (
                      <tr>
                        <td>
                          <strong>Numri i leternjoftimit</strong>
                        </td>
                        <td>{this.state.user_data.identity_card}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.activity ? (
                      <tr>
                        <td>
                          <strong>Aktiviteti</strong>
                        </td>
                        <td>{this.state.user_data.activity.name}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.department ? (
                      <tr>
                        <td>
                          <strong>Departamenti</strong>
                        </td>
                        <td>
                          {this.state.user_data.department.map((element, index) => {
                            return <p key={index}>{element.name}</p>
                          })}
                        </td>
                      </tr>
                    ) : null}
                    {this.state.user_data.project ? (
                      <tr>
                        <td>
                          <strong>Projekti</strong>
                        </td>
                        <td>{this.state.user_data.project.name}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.line ? (
                      <tr>
                        <td>
                          <strong>Linja</strong>
                        </td>
                        <td>{this.state.user_data.line}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.user_level ? (
                      <tr>
                        <td>
                          <strong>Niveli i Perdoruesit</strong>
                        </td>
                        {this.state.user_data.user_level == 1 ? <td>Administrator</td> : null}
                        {this.state.user_data.user_level == 2 ? <td>Manager</td> : null}
                        {this.state.user_data.user_level == 3 ? <td>Team Leader</td> : null}
                        {this.state.user_data.user_level == 4 ? <td>Perdorues i thjeshte</td> : null}
                        {this.state.user_data.user_level == 5 ? <td>HR Manager</td> : null}
                        {this.state.user_data.user_level == 6 ? <td>HR Admin</td> : null}
                        {this.state.user_data.user_level == 7 ? <td>Mirembajtes</td> : null}
                        {this.state.user_data.user_level == 8 ? <td>Koordinator i pushimeve</td> : null}
                      </tr>
                    ) : null}
                    {this.state.user_data.languages ? (
                      <tr>
                        <td>
                          <strong>Gjuhet</strong>
                        </td>
                        <td>
                          {this.state.user_data.languages.map((language, index) => {
                            return (
                              <p key={index}>
                                {language._id ? this.state.languages[language._id] : ''}- {language.level}
                              </p>
                            )
                          })}
                        </td>
                      </tr>
                    ) : null}
                    {this.state.user_data.address ? (
                      <tr>
                        <td>
                          <strong>Adresa</strong>
                        </td>
                        <td>{this.state.user_data.address}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.city ? (
                      <tr>
                        <td>
                          <strong>Qyteti</strong>
                        </td>
                        <td>{this.state.user_data.city.name}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.birthdate ? (
                      <tr>
                        <td>
                          <strong>Data e lindjes</strong>
                        </td>
                        <td>{moment(this.state.user_data.birthdate).format('DD/MM/YYYY')}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.nationality ? (
                      <tr>
                        <td>
                          <strong>Nacionaliteti</strong>
                        </td>
                        <td>{this.state.user_data.nationality}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>
                        <strong>Shtetësia</strong>
                      </td>
                      <td>{this.state.user_data.citizenship ? this.state.user_data.citizenship : 'Ende e padefinuar'}</td>
                    </tr>
                    {this.state.user_data.citizenship &&
                    !this.state.user_data.citizenship.toLowerCase().includes('kosov') &&
                    (this.state.user_data.residence_permit === true || this.state.user_data.residence_permit === false) ? (
                      <tr>
                        <td>
                          <strong>Lejeqëndrimi</strong>
                        </td>
                        <td>{this.state.user_data.residence_permit ? 'Po' : `Jo`}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.gender ? (
                      <tr>
                        <td>
                          <strong>Gjinia</strong>
                        </td>
                        <td>{this.state.user_data.gender}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>
                        <strong>Certifikata shëndetësore</strong>
                      </td>
                      <td>{this.state.user_data.health_certificate ? 'E ka dorëzuar' : 'Nuk e ka dorëzuar'}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>SSHP</strong>
                      </td>
                      <td>{this.state.user_data.health_safety_certificate ? 'E ka dorëzuar' : 'Nuk e ka dorëzuar'}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Certifikata nën hetime</strong>
                      </td>
                      <td>{this.state.user_data.court_certificate ? 'E ka dorëzuar' : 'Nuk e ka dorëzuar'}</td>
                    </tr>
                    {this.state.user_data.manager ? (
                      <tr>
                        <td>
                          <strong>Menaxher</strong>
                        </td>
                        <td>
                          {this.state.user_data.manager.first_name} {this.state.user_data.manager.last_name}
                        </td>
                      </tr>
                    ) : null}
                    {this.state.user_data.teamleader ? (
                      <tr>
                        <td>
                          <strong>Team Leader</strong>
                        </td>
                        <td>
                          {this.state.user_data.teamleader.first_name} {this.state.user_data.teamleader.last_name}
                        </td>
                      </tr>
                    ) : null}
                    {this.state.user_data.function ? (
                      <tr>
                        <td>
                          <strong>Funksioni</strong>
                        </td>
                        <td>{this.state.user_data.function.name}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>
                        <strong>Rroga baze</strong>
                      </td>
                      <td>{this.state.user_data.base_salary || 0}</td>
                    </tr>
                    {this.state.user_data.bank ? (
                      <tr>
                        <td>
                          <strong>Banka</strong>
                        </td>
                        <td>{this.state.user_data.bank.name}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.bank_account_no ? (
                      <tr>
                        <td>
                          <strong>Llogaria Bankare</strong>
                        </td>
                        <td>{this.state.user_data.bank_account_no}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.trust_account ? (
                      <tr>
                        <td>
                          <strong>Llogaria në Trust</strong>
                        </td>
                        <td>{this.state.user_data.trust_account ? 'Po' : 'Jo'}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.office_location ? (
                      <tr>
                        <td>
                          <strong>Lokacioni i punës</strong>
                        </td>
                        <td>{this.state.user_data.office_location}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.work ? (
                      <tr>
                        <td>
                          <strong>Statusi i punës</strong>
                        </td>
                        <td>{this.state.user_data.work.name}</td>
                      </tr>
                    ) : null}
                    {this.state.user_data.first_call ? (
                      <tr>
                        <td>
                          <strong>Dita e pare</strong>
                        </td>
                        <td>{moment(this.state.user_data.first_call).format('DD/MM/YYYY')}</td>
                      </tr>
                    ) : null}
                    {this.state.resingProcess ? (
                      <tr>
                        <td>
                          <strong>Intervista e daljes</strong>
                        </td>
                        <td>
                          <ResignInterviewModal />
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>

          <Col lg='8'>
            <div className='card card-default'>
              <div className='card-header d-flex align-items-center'>
                <div className='d-flex justify-content-center col'>
                  <div className='h4 m-0 text-center'>Edito të dhënat personale</div>
                </div>
              </div>
              <div className='card-body'>
                <div className='row py-4 justify-content-center'>
                  <div className='col-12 col-sm-10'>
                    <form onSubmit={this.onSubmit} name='formProfile' className='form-horizontal'>
                      <div className='form-group row'>
                        <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                          Telefoni
                        </label>
                        <div className='col-xl-10 col-md-9 col-8'>
                          <Input
                            type='text'
                            name='phone'
                            invalid={this.hasError('formProfile', 'phone', 'required') || this.hasError('formProfile', 'phone', 'number')}
                            onChange={this.validateOnChange}
                            data-validate='["required", "number"]'
                            value={this.state.formProfile.phone || ''}
                            placeholder='3834xxxxxxx'
                          />
                          {this.hasError('formProfile', 'phone', 'required') && (
                            <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                          )}
                          {this.hasError('formProfile', 'phone', 'number') && (
                            <span className='invalid-feedback'>Ju lutem shenoni numer</span>
                          )}
                        </div>
                      </div>

                      <div className='form-group row'>
                        <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='address'>
                          Adresa
                        </label>
                        <div className='col-xl-10 col-md-9 col-8'>
                          <Input
                            type='text'
                            name='address'
                            invalid={this.hasError('formProfile', 'address', 'required')}
                            onChange={this.validateOnChange}
                            data-validate='["required"]'
                            value={this.state.formProfile.address || ''}
                          />
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </div>
                      </div>
                      <div className='form-group row'>
                        <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='city'>
                          Qyteti
                        </label>
                        <div className='col-xl-10 col-md-9 col-8'>
                          <Input
                            type='select'
                            name='city'
                            invalid={this.hasError('formProfile', 'city', 'required')}
                            onChange={this.validateOnChange}
                            data-validate='["required"]'
                            value={this.state.formProfile.city || ''}
                            placeholder='Rruga Filan Fisteku, 30'
                          >
                            {this.state.cities.map((city) => {
                              return (
                                <option key={city._id} value={city._id}>
                                  {city.name}
                                </option>
                              )
                            })}
                          </Input>
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </div>
                      </div>

                      <div className='form-group row'>
                        <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                          Kontakti emergjent
                        </label>
                        <div className='col-xl-10 col-md-9 col-8'>
                          <Input
                            type='text'
                            name='contactEmergency'
                            onChange={this.validateOnChange}
                            value={this.state.formProfile.contactEmergency || ''}
                            placeholder=''
                          />
                        </div>
                      </div>

                      {/* <div className='form-group row'>
                        <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                          Keni kaluar Covid-in?
                        </label>
                        <div className='col-xl-10 col-md-9 col-8'>
                          <Input
                            type='select'
                            name='covid'
                            invalid={this.hasError('formProfile', 'covid', 'required')}
                            onChange={this.validateOnChange}
                            value={this.state.formProfile.covid}
                            data-validate='["required"]'
                            className='custom-select custom-select'
                          >
                            <option key={'0'} value=''>
                              Zgjedhni pergjigjen:
                            </option>
                            <option key={'1'} value='Po'>
                              Po
                            </option>
                            <option key={'2'} value='Jo'>
                              Jo
                            </option>
                          </Input>
                          <span className='invalid-feedback'>Fusha është e nevojshme</span>
                        </div>
                      </div>
                      {this.state.formProfile.covid == 'Po' && (
                        <div className='form-group row'>
                          <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                            Viti i infektimit:
                          </label>
                          <div className='col-xl-10 col-md-9 col-8'>
                            <Input
                              type='select'
                              name='year'
                              invalid={this.hasError('formProfile', 'year', 'required')}
                              onChange={this.validateOnChange}
                              value={this.state.formProfile.year}
                              data-validate='["required"]'
                              className='custom-select custom-select'
                            >
                              <option key={'0'} value=''>
                                Zgjedhni pergjigjen:
                              </option>
                              <option key={'1'} value='2020'>
                                2020
                              </option>
                              <option key={'2'} value='2021'>
                                2021
                              </option>
                            </Input>
                            <span className='invalid-feedback'>Fusha është e nevojshme</span>
                          </div>
                        </div>
                      )}
                      <div className='form-group row'>
                        <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                          Jeni vakcionuar?
                        </label>
                        <div className='col-xl-10 col-md-9 col-8'>
                          <Input
                            type='select'
                            name='vaccinated'
                            invalid={this.hasError('formProfile', 'vaccinated', 'required')}
                            onChange={this.validateOnChange}
                            value={this.state.formProfile.vaccinated}
                            data-validate='["required"]'
                            className='custom-select custom-select'
                          >
                            <option key={'0'} value=''>
                              Zgjedhni pergjigjen:
                            </option>
                            <option key={'1'} value='Doza 1'>
                              Doza 1
                            </option>
                            <option key={'2'} value='Doza 2'>
                              Doza 2
                            </option>
                            <option key={'3'} value='Refuzoj të vakcionohem'>
                              Refuzoj të vakcionohem
                            </option>
                            <option key={'4'} value='Jam në pritje'>
                              Jam në pritje
                            </option>
                          </Input>
                          <span className='invalid-feedback'>Fusha është e nevojshme</span>
                        </div>
                      </div>

                      {this.state.formProfile.vaccinated == 'Doza 1' || this.state.formProfile.vaccinated == 'Doza 2' ? (
                        <div className='form-group row'>
                          <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                            Pasaporta e vakcionimit:
                          </label>
                          <div className='col-xl-10 col-md-9 col-8'>
                            <Input
                              type='text'
                              name='passport'
                              invalid={this.hasError('formProfile', 'passport', 'required')}
                              onChange={this.validateOnChange}
                              data-validate='["required"]'
                              value={this.state.formProfile.passport || ''}
                            />
                            <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                          </div>
                        </div>
                      ) : null} */}

                      <div className='form-group row float-right'>
                        <div className='col-md-10'>
                          <button className='btn btn-info' type='submit'>
                            Ruaj
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className='card card-default'>
              <div className='card-header d-flex align-items-center'>
                <div className='d-flex justify-content-center col'>
                  <div className='h4 m-0 text-center'>Vërejtjet e lëshuara</div>
                </div>
              </div>
              <div className='card-body'>
                {this.state.userWarnings.length == 0 ? (
                  <div className='d-flex justify-content-center col'>
                    <div className='h6 m-0 text-center'>Nuk ka vërejtje</div>
                  </div>
                ) : (
                  <Fragment>
                    {this.state.userWarnings.map((warning, index) => {
                      return (
                        <div key={index} className='media mb-2'>
                          <img className='mr-2 rounded-circle thumb32' src={'/img/blank_profile.png'} />
                          <div className='media-body'>
                            <span>
                              <p className='m-0'>
                                Lëshuar nga <strong>{`${warning.created_by.first_name} ${warning.created_by.last_name}`}</strong> me datë{' '}
                                {moment(warning.created_date).format('DD/MM/YYYY')} për datën{' '}
                                {moment(warning.issue_date).format('DD/MM/YYYY')}
                                {warning.type && (
                                  <Button
                                    style={{
                                      pointerEvents: 'none',
                                      color: 'white',
                                      backgroundColor: warning.type === 'verbal' ? '#FF902B' : '#5D9CEC'
                                    }}
                                    size='xs'
                                    className='btn-labeled float-right'
                                  >
                                    {warning.type === 'verbal' ? 'Verbale' : 'Shkruar'}
                                  </Button>
                                )}
                              </p>
                              {warning.warnings.map((w, index) => {
                                return (
                                  <div key={index} className='list-group mb0'>
                                    <div className='list-group-item bt0'>
                                      <em className='fa-fw fas fa-exclamation-triangle mr-2' />
                                      {w.description}
                                    </div>
                                  </div>
                                )
                              })}
                            </span>
                          </div>
                        </div>
                      )
                    })}
                  </Fragment>
                )}
              </div>
            </div>
            <div className='card card-default'>
              <div className='card-header d-flex align-items-center'>
                <div className='d-flex justify-content-center col'>
                  <div className='h4 m-0 text-center'>Folderi Digjital</div>
                </div>
              </div>
              <div className='card-body'>
                {this.state.files.length == 0 ? (
                  <div className='d-flex justify-content-center col'>
                    <div className='h6 m-0 text-center'>Nuk ka file të ngarkuar</div>
                  </div>
                ) : (
                  <Col lg='12'>
                    <Row>
                      {this.state.files.map((file, index) => {
                        return (
                          <Col lg='4' key={index} className={`${this.state.loadingFile[file._id] ? 'whirl standard' : ''}`}>
                            <div className='card' data-filter-group='audio'>
                              <div className='card-body text-center'>
                                {file._id && (
                                  <div className='d-flex'>
                                    <div className='ml-auto' />
                                  </div>
                                )}
                                <a
                                  onClick={(e) => {
                                    e.preventDefault()
                                    if (file.uploading) {
                                      return
                                    } else {
                                      this.downloadFile(file)
                                    }
                                  }}
                                  href='#'
                                  className='px-4'
                                >
                                  <em className={`fa-3x far ${mimetype2fa(file.mime_type)} text-info`} />
                                </a>
                                <p>
                                  <small className='text-dark'>{this.messageCrop(file.filename)}</small>
                                </p>
                                <div className='d-flex m-0 text-muted'>
                                  <small>{prettyBytes(parseInt(file.file_size))}</small>
                                  <small className='ml-auto'>{moment(file.created_date).fromNow()}</small>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                  </Col>
                )}
              </div>
            </div>
            <ResignInterviewCard
              userID={this.props.userStore.user_id}
              firstName={this.state.user_data.first_name}
              lastName={this.state.user_data.last_name}
            />
          </Col>
        </Row>
      </ContentWrapper>
    )
  }
}

export default Wages
