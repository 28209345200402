import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@material-ui/icons'
import { Autocomplete, Checkbox, Divider, TextField } from '@mui/material'
import axios from 'axios'
import 'lightbox-react/style.css' // This only needs to be imported once in your app
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button, CardBody, CardHeader, CardTitle, Col, Collapse, Input, InputGroup, InputGroupAddon } from 'reactstrap'
import DateTimeBoostrap from '../../../components/Forms/DateTimeBootstrap'
import { APPLICANTS_API } from '../../../utils/consts'
import { useDataQuery } from '../applicantRequests'
import { getApplicantsInput, requiredApplicantFields } from '../enums/applicantsInput'
import { saveAs } from 'file-saver'

export const unCheckedIcon = <CheckBoxOutlineBlank fontSize='small' />
export const checkedIcon = <CheckBoxOutlined fontSize='small' />

const ApplicantDataTable = ({ applicant, setApplicantData, applicantData, applicantStatus }) => {
  const [applicantIdDocs, setApplicantIdDocs] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedUserStatus, setSelectedUserStatus] = useState('')
  const [languages, setLanguages] = useState([])
  const [searchRecruiter, setSearchRecruiter] = useState('')
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [openLanguage, setOpenLanguage] = useState([])

  const { data: users } = useDataQuery('users', `users/activeandinactive?page=1&limit=20&search=${searchTerm}`)
  const { data: cities } = useDataQuery('cities', 'cities')
  const { data: activities } = useDataQuery('activities', 'activities')
  const { data: banks } = useDataQuery('banks', 'banks')
  const { data: works } = useDataQuery('works', 'works')
  const { data: projectManagers } = useDataQuery('projectManagers', 'users/projectManagers')
  const { data: managers } = useDataQuery('managers', 'users/managers')
  const { data: teamLeaders } = useDataQuery('teamleaders', 'users/teamleaders')
  const { data: roles } = useDataQuery('roles', 'roles')
  const { data: projects } = useDataQuery('projects', 'projects')
  const { data: languagesData } = useDataQuery('languages', 'languages')
  const { data: recruiters } = useDataQuery('recruiters', 'users/hrRecruiters')
  const depQuery = applicantData.project ? `departments?projectId=${applicantData.project}` : `departments`
  const { data: departments } = useDataQuery('departments', depQuery, depQuery)
  let loading =
    !users ||
    !cities ||
    !activities ||
    !banks ||
    !works ||
    !projectManagers ||
    !managers ||
    !teamLeaders ||
    !roles ||
    !projects ||
    !languagesData ||
    !recruiters ||
    !departments

  useEffect(() => {
    if (languagesData && applicant) {
      applicant.languageAbility.forEach((item) => {
        setOpenLanguage((prev) => [
          ...prev.filter((oneP) => oneP !== item.value),
          languagesData.find((oneI) => oneI?.name?.slice(0, 2) === item?.value?.slice(0, 2))?._id
        ])
        setSelectedLanguages((prev) => [
          ...prev,
          { _id: languagesData.find((oneL) => oneL?.name?.slice(0, 2) === item?.value?.slice(0, 2))?._id, level: item?.additionalInfo }
        ])
      })

      languagesData.forEach((item) =>
        setLanguages((prev) => [...prev?.filter((onePrev) => onePrev._id != item._id), { ...item, checked: false }])
      )
    }
  }, [languagesData, applicant])

  let formattedRecruiters = []
  useEffect(() => {
    if (recruiters) {
      formattedRecruiters = recruiters.map((oneRecruiter) => ({
        _id: oneRecruiter._id,
        email: oneRecruiter.email,
        department: oneRecruiter.department,
        name: `${oneRecruiter.first_name} ${oneRecruiter.last_name}`
      }))
    }
  }, [recruiters])

  useEffect(() => {
    if (applicant.idCard.length) {
      applicant.idCard.forEach((idCard) => {
        setApplicantIdDocs((prevState) => [...prevState?.filter((prev) => prev != idCard), idCard])
      })
    }
  }, [applicant.idCard])

  useEffect(() => {
    setApplicantData((prev) => {
      return { ...prev, languages: selectedLanguages }
    })
  }, [selectedLanguages])

  useEffect(() => {
    if (!loading) {
      const initialSelectedUser = users.find((user) => user.email === applicantData.referred_by?.[0]?.email)
      setSelectedUserStatus(initialSelectedUser?.is_active ? 'Aktiv' : 'Exit')
    }
  }, [users])

  const validateOnChange = async (event) => {
    const input = event.target
    const form = input.name
    const value = input.type === 'checkbox' ? input.checked : input.value
    if (form === 'project') {
      setApplicantData((prev) => {
        return {
          ...prev,
          [form]: form === 'department' ? [value] : value,
          department: []
        }
      })
    } else {
      setApplicantData((prev) => {
        return {
          ...prev,
          [form]: form === 'department' ? [value] : value
        }
      })
    }
  }

  const handleUserSelection = (newValue) => {
    setSelectedUserStatus(newValue ? (newValue.is_active ? 'Aktiv' : 'Exit') : '')
    setApplicantData((prev) => ({
      ...prev,
      referred_by: [
        {
          first_name: newValue?.first_name,
          last_name: newValue?.last_name,
          is_active: newValue?.is_active,
          email: newValue?.email,
          user_id: newValue?._id
        }
      ]
    }))
  }

  const handleRecruiterSelection = (newValue) => {
    setApplicantData((prev) => ({
      ...prev,
      recruited_by: newValue ? [newValue] : []
    }))
  }

  const onDateChange = async (event, name) => {
    if (typeof event == 'object') {
      setApplicantData((prev) => {
        return {
          ...prev,
          [name]: event
        }
      })
    } else {
      setApplicantData((prev) => {
        return {
          ...prev,
          [name]: null
        }
      })
    }
  }

  const applicantsInput = getApplicantsInput(
    cities,
    activities,
    banks,
    works,
    projects,
    departments,
    managers,
    projectManagers,
    teamLeaders,
    roles,
    languages,
    applicantStatus
  )

  const halfOfApplicantInput = Math.ceil(applicantsInput.length / 2)
  const firstArray = applicantsInput.slice(0, halfOfApplicantInput)
  const secondArray = applicantsInput.slice(halfOfApplicantInput)
  const finalApplicantsInput = [firstArray, secondArray]

  const downloadFile = async (doc, fileName) => {
    try {
      const res = await axios.get(`${APPLICANTS_API}/applicants/file?key=${doc}`, { withCredentials: false })
      if (res.data.success) {
        try {
          const response = await fetch(res.data.url)
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          const blob = await response.blob()
          saveAs(blob, fileName)
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error)
        }
      }
    } catch (error) {}
  }

  return loading ? (
    <div>Loading...</div>
  ) : (
    <div>
      <div className='row' style={{ width: '97%' }}>
        <div
          className='col-8'
          style={{
            width: '100%',
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginBottom: '30px'
          }}
        >
          <label className='col-form-label'>Referuesi: </label>
          <Autocomplete
            fullWidth
            options={users}
            getOptionLabel={(option) => `${option?.first_name} ${option?.last_name} - ${option?.email}`}
            onChange={(_event, newValue) => handleUserSelection(newValue)}
            value={!applicantData.referred_by[0]?.last_name ? null : applicantData.referred_by[0]}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option._id}>
                <Checkbox icon={unCheckedIcon} checkedIcon={checkedIcon} checked={selected} style={{ marginRight: 8 }} />
                <div className='row' style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
                  <div className='col-4'>{`${option?.first_name} ${option?.last_name}`}</div>
                  <div className='col-4' style={{ opacity: '0.5', fontWeight: 'bold' }}>{`${option?.is_active ? 'Aktiv' : 'Exit'}`}</div>
                  <div className='col-4' style={{ opacity: '0.5', fontWeight: 'bold' }}>{`${option?.email}`}</div>
                </div>
              </li>
            )}
            disabled={applicantStatus === 'recruited'}
            style={{ width: '100%', borderBottom: '1px solid #ccc' }}
            renderInput={(params) => (
              <TextField
                {...params}
                className='fieldi'
                label='Shkruaj emrin e referuesit'
                placeholder='Users'
                size='small'
                fullWidth
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                sx={{ border: 'none !important', ['> fieldset']: { borderColor: 'transparent' } }}
              />
            )}
          />
        </div>
        <div className='col-4'>
          <TextField
            type='text'
            size='small'
            style={{ marginRight: '100px', borderBottom: '1px solid #ccc' }}
            label='Statusi i referuesit'
            value={selectedUserStatus || ''}
            fullWidth
            InputProps={{ readOnly: true }}
            disabled={applicantStatus === 'recruited'}
          />
        </div>
      </div>
      <Divider
        sx={{
          borderColor: '#ccc',
          border: 'none',
          marginBottom: '30px',
          bgcolor: '#ccc',
          borderTop: '1px solid #ccc !important',
          width: '95%'
        }}
      />

      <div
        style={{
          width: '100%',
          marginBottom: '20px',
          display: 'flex'
        }}
      >
        <div style={{ width: '50%' }}>
          <div>
            <h4>Pozita për të cilën ka aplikuar: </h4>
            <h6 style={{ textDecoration: 'underline' }}>{applicant.position}</h6>
          </div>
          <div>
            <h4>Diasporë: </h4>
            <h6 style={{ textDecoration: 'underline' }}>{applicant.is_diaspore ? 'PO' : 'JO'}</h6>
          </div>
        </div>

        <div>
          <h4>Dokumente: </h4>
          {applicantIdDocs.length
            ? applicantIdDocs.map((doc, index) => {
                const parts = doc.split('.')
                const fileName = `${applicant?.firstName} ${applicant?.lastName} - Docs ${applicantIdDocs.length > 1 ? index + 1 : ''}.${
                  parts[parts?.length - 1]
                }`
                return (
                  <div key={index}>
                    <Button
                      onClick={() => {
                        downloadFile(doc, fileName)
                      }}
                      style={{ marginBottom: '5px' }}
                      className='uploadDownload'
                    >
                      {fileName}
                    </Button>
                  </div>
                )
              })
            : ''}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          className=''
          style={{ width: '99%', display: 'flex', gap: '0px', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '30px' }}
        >
          <label className='col-form-label' style={{ width: '200px' }}>
            Rekruteri:{' '}
          </label>
          <Col md={4}>
            <Autocomplete
              fullWidth
              options={recruiters}
              disabled={applicantStatus === 'recruited'}
              getOptionLabel={(option) =>
                `${option?.name ? option?.name : option?.first_name ? option?.first_name + option.last_name : ''} - ${option?.email}`
              }
              onChange={(_, value) => handleRecruiterSelection(value)}
              value={applicantData?.recruited_by[0] || null}
              style={{ width: '100%', borderBottom: '1px solid #ccc' }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option._id}>
                  <Checkbox icon={unCheckedIcon} checkedIcon={checkedIcon} checked={selected} style={{ marginRight: 8 }} />
                  <div className='row' style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
                    <div className='col-6'>{`${option?.first_name} ${option?.last_name}`}</div>
                    <div className='col-6' style={{ opacity: '0.5', fontWeight: 'bold' }}>{`${option?.email}`}</div>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Emri i rekruterit'
                  placeholder='Rekruteri'
                  size='small'
                  fullWidth
                  onChange={(e) => setSearchRecruiter(e.target.value)}
                  value={searchRecruiter}
                  sx={{ border: 'none !important', ['> fieldset']: { borderColor: 'transparent' } }}
                />
              )}
            />
          </Col>
        </div>
        <div style={{ display: 'flex', gap: '20px' }}>
          {finalApplicantsInput.map((oneRow) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%'
              }}
            >
              {oneRow.map((oneInput, index) => {
                return (
                  !!oneInput && (
                    <div
                      style={{
                        display: oneInput.hide ? 'none' : 'flex',
                        marginBottom:
                          applicantStatus !== 'recruited' || (applicantStatus === 'recruited' && oneInput.value !== 'password')
                            ? '20px'
                            : '0' // Change '0' to whatever default marginBottom value you want
                      }}
                      key={index}
                    >
                      {(applicantStatus !== 'recruited' || (applicantStatus === 'recruited' && oneInput.value !== 'password')) && (
                        <label className='col-form-label' style={{ width: '200px' }}>
                          {oneInput.text}
                        </label>
                      )}
                      <Col md={8}>
                        {oneInput.select ? (
                          oneInput.value === 'languages' ? (
                            languages.map((item, index) => {
                              return (
                                <div key={index} className='card b mb-8'>
                                  <CardHeader>
                                    <CardTitle>
                                      <div className='checkbox c-checkbox'>
                                        <label>
                                          <Input
                                            disabled={applicantStatus === 'recruited'}
                                            key={item._id}
                                            name={item.name}
                                            checked={openLanguage.find((openL) => openL === item._id)}
                                            onChange={(e) => {
                                              setOpenLanguage(() => {
                                                if (e.target.checked) {
                                                  return [...openLanguage.filter((openL) => openL !== item._id), item._id]
                                                } else {
                                                  return [
                                                    ...openLanguage.filter((openL) => openL !== item._id),
                                                    setSelectedLanguages((prev) => [...prev.filter((oneSL) => oneSL._id != item._id)])
                                                  ]
                                                }
                                              })
                                            }}
                                            type='checkbox'
                                            value={item._id || ''}
                                          />
                                          <span className='fa fa-check' />
                                          {item.name}
                                        </label>
                                      </div>
                                    </CardTitle>
                                  </CardHeader>
                                  <Collapse isOpen={openLanguage.find((openL) => openL === item._id)}>
                                    <CardBody id='collapse01'>
                                      <Input
                                        key={item._id}
                                        type='select'
                                        name='level'
                                        onChange={(e) => {
                                          openLanguage.find((oneL) => oneL === item._id) &&
                                            setSelectedLanguages((prev) => [
                                              ...prev.filter((oneSL) => oneSL._id != item._id),
                                              {
                                                level: e.target.value,
                                                _id: item._id
                                              }
                                            ])
                                        }}
                                        value={selectedLanguages.find((oneSL) => oneSL._id === item._id)?.level || ''}
                                        placeholder=''
                                        disabled={applicantStatus === 'recruited'}
                                      >
                                        <option key={'0'} value=''>
                                          Zgjedhni Nivelin e Gjuhes...
                                        </option>
                                        <option key={'A1'} value='A1'>
                                          A1
                                        </option>
                                        <option key={'A2'} value='A2'>
                                          A2
                                        </option>
                                        <option key={'B1'} value='B1'>
                                          B1
                                        </option>
                                        <option key={'B2'} value='B2'>
                                          B2
                                        </option>
                                        <option key={'C1'} value='C1'>
                                          C1
                                        </option>
                                        <option key={'C2'} value='C2'>
                                          C2
                                        </option>
                                      </Input>
                                    </CardBody>
                                  </Collapse>
                                </div>
                              )
                            })
                          ) : (
                            <>
                              <Input
                                type='select'
                                name={oneInput.value}
                                value={applicantData[oneInput.value]}
                                onChange={(e) => validateOnChange(e)}
                                disabled={applicantStatus === 'recruited'}
                                invalid={
                                  applicantStatus === 'waiting_list' &&
                                  requiredApplicantFields.find(
                                    (item) =>
                                      item.value === oneInput.value &&
                                      (!applicantData[oneInput.value] ||
                                        applicantData[oneInput.value].length === 0 ||
                                        (oneInput.value === 'bank_account_no' && applicantData[oneInput.value]?.length !== 16) ||
                                        (oneInput.value === 'password' && applicantData[oneInput.value]?.length < 8))
                                  )
                                }
                              >
                                <option key={'0'} value=''>
                                  Zgjedhni ...
                                </option>
                                {oneInput.select?.map((oneOption) => {
                                  return (
                                    <option key={oneOption.key || oneOption._id} value={oneOption.value || oneOption._id}>
                                      {oneOption.text || oneOption.name || oneOption.first_name + ' ' + oneOption.last_name}
                                    </option>
                                  )
                                })}
                              </Input>
                            </>
                          )
                        ) : oneInput.checkbox ? (
                          <>
                            <div className='d-flex align-items-center'>
                              <label className='switch switch-lg'>
                                <input
                                  name={oneInput.value}
                                  value={applicantData[oneInput.value]}
                                  disabled={applicantStatus === 'recruited'}
                                  type='checkbox'
                                  onChange={(e) => validateOnChange(e)}
                                  defaultChecked={applicantData[oneInput.value] !== null ? applicantData[oneInput.value] : false}
                                />
                                {applicantData[oneInput.value] === true ? (
                                  <span className='invalid-feedback' />
                                ) : (
                                  <span style={{ marginTop: '4px' }} />
                                )}
                              </label>
                            </div>
                          </>
                        ) : oneInput.datepicker ? (
                          <>
                            <DateTimeBoostrap
                              key={oneInput.value}
                              onChange={(e) => onDateChange(e, oneInput.value)}
                              value={applicantData[oneInput.value] ? moment(applicantData[oneInput.value]) : null}
                              utc={true}
                              inputFormat='dd/MM/yyyy'
                              timeFormat={false}
                              inputProps={{
                                className: 'form-control',
                                name: oneInput.value,
                                autoComplete: 'off',
                                disabled: applicantStatus === 'recruited'
                              }}
                            />
                          </>
                        ) : (
                          <>
                            {(applicantStatus !== 'recruited' || (applicantStatus === 'recruited' && oneInput.value !== 'password')) && (
                              <InputGroup>
                                <Input
                                  type={oneInput.type ? oneInput.type : 'text'}
                                  name={oneInput.value}
                                  placeholder={oneInput.placeholder}
                                  value={applicantData[oneInput.value]}
                                  onChange={(e) => validateOnChange(e)}
                                  disabled={applicantStatus === 'recruited'}
                                  invalid={
                                    applicantStatus === 'waiting_list' &&
                                    requiredApplicantFields.find(
                                      (item) =>
                                        item.value === oneInput.value &&
                                        (!applicantData[oneInput.value] ||
                                          applicantData[oneInput.value].length === 0 ||
                                          (oneInput.value === 'bank_account_no' && applicantData[oneInput.value]?.length !== 16) ||
                                          (oneInput.value === 'password' && applicantData[oneInput.value]?.length < 8))
                                    )
                                  }
                                />
                                {oneInput.type === 'number' && (
                                  <InputGroupAddon addonType='append'>
                                    <Button
                                      disabled={applicantStatus === 'recruited'}
                                      onClick={() => {
                                        setApplicantData((prev) => {
                                          return {
                                            ...prev,
                                            [oneInput.value]: parseInt(prev[oneInput.value]) + 1
                                          }
                                        })
                                      }}
                                      className='font-weight-bold'
                                    >
                                      +
                                    </Button>
                                    <Button
                                      disabled={applicantStatus === 'recruited'}
                                      onClick={() => {
                                        setApplicantData((prev) => {
                                          return {
                                            ...prev,
                                            [oneInput.value]: parseInt(prev[oneInput.value]) - 1
                                          }
                                        })
                                      }}
                                      className='font-weight-bold'
                                    >
                                      -
                                    </Button>
                                  </InputGroupAddon>
                                )}
                              </InputGroup>
                            )}
                          </>
                        )}
                        {applicantStatus === 'waiting_list' &&
                          requiredApplicantFields.find(
                            (item) =>
                              item.value === oneInput.value &&
                              ((!applicantData[oneInput.value] &&
                                oneInput.value != 'health_certificate' &&
                                oneInput.value != 'court_certificate') ||
                                applicantData[oneInput.value].length === 0 ||
                                (oneInput.value === 'bank_account_no' && applicantData[oneInput.value]?.length !== 16) ||
                                (oneInput.value === 'password' && applicantData[oneInput.value]?.length < 8))
                          ) && (
                            <span style={{ color: 'red', fontSize: '12px', paddingLeft: '4px' }}>
                              Fusha është e nevojshme{' '}
                              {oneInput.value === 'bank_account_no' &&
                                applicantData[oneInput.value]?.length !== 16 &&
                                'dhe duhet te i permbaje 16 karaktere'}
                              {oneInput.value === 'password' &&
                                applicantData[oneInput.value]?.length < 8 &&
                                'dhe duhet te i permbaje se paku 8 karaktere'}
                            </span>
                          )}
                      </Col>
                    </div>
                  )
                )
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ApplicantDataTable
