import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Base from './components/Layout/Base'
import Login from './components/Pages/Login'
import PasswordReset from './components/Pages/PasswordReset'
import RecoverPassword from './components/Pages/RecoverPassword'
import { getRoutesData } from './components/Layout/getRoutesData'
import Cookies from 'js-cookie'
const isTokenValid = (token) => {
  if (!token) return false

  try {
    const decodedToken = jwt_decode(token)
    return decodedToken.exp * 1000 > Date.now() // Check if token is expired
  } catch (error) {
    return false
  }
}

const Routes = ({ location }) => {
  const localStorageToken = localStorage.getItem('token')
  const cookieToken = Cookies.get('hrm_auth_token')

  if (!isTokenValid(cookieToken) || !isTokenValid(localStorageToken)) {
    Cookies.remove('hrm_auth_token')
    localStorage.removeItem('token') // Ensure token is cleared
    return (
      <div className='wrapper'>
        <Switch location={location}>
          <Route path='/login' component={Login} />
          <Route path='/recoverPassword' component={RecoverPassword} />
          <Route path='/passwordReset' component={PasswordReset} />
          {/* Redirect unmatched routes to login */}
          <Redirect to='/login' />
        </Switch>
      </div>
    )
  }

  const decodedToken = jwt_decode(localStorageToken)
  const { user_level } = decodedToken
  const routes = getRoutesData()

  return (
    <Base>
      <Switch location={location}>
        {routes.map((item, i) =>
          item?.allowed_user_levels.includes(user_level) || item.allowed_condition ? <Route key={i} {...item} /> : null
        )}
      </Switch>
    </Base>
  )
}

export default withRouter(Routes)
