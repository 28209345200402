import axios from 'axios'
import 'moment/locale/sq'
import pubsub from 'pubsub-js'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Col, Row } from 'reactstrap'
import { getAwsDocuments } from '../../utils/awsPaths'
import { DOCUMENTS_API } from '../../utils/consts'
import ContentWrapper from '../Layout/ContentWrapper'
import File from './FileManager/File'

function FileManager({ userId }) {
  const [loading, setLoader] = useState(true)
  const [files, setFiles] = useState([])

  useEffect(() => {
    setLoader(true)
    try {
      axios
        .get(getAwsDocuments(userId), { withCredentials: false })
        .then((data) => {
          setFiles(data.data)
        })
        .catch((err) => console.log('error', err))
    } catch (e) {
    } finally {
      setLoader(false)
    }
  }, [])

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    const data = new FormData()
    setLoader(true)
    data.append('userId', userId)

    acceptedFiles.map((file) => data.append('files', file))

    axios
      .post(`${DOCUMENTS_API}/documents`, data, { withCredentials: false })
      .then((fileResponse) => {
        setFiles((prevState) => [...prevState, ...fileResponse.data])
        pubsub.publish('showNotification', {
          message: 'Dokumenti u shtua me sukses.',
          type: 'success'
        })
      })
      .catch((error) => {
        console.log(error, 'fdsfsdf')
        // Retrieve the error message from the backend response, if available.
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Gabim gjate shtimit te dokumentit'
        pubsub.publish('showNotification', {
          message: errorMessage,
          type: 'error'
        })
      })
      .finally(() => setLoader(false))
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <ContentWrapper>
      <Row>
        <Col lg='12'>
          <div className='mb-3'>
            <div className='card'>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div className='text-center box-placeholder m-0'>Ngarko files</div>
                ) : (
                  <div className='text-center box-placeholder m-0'>Barti files ketu ose kliko per te ngarkuar</div>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col className={`${loading && 'whirl standard'}`} lg='12'>
          <Row>
            {files.map((file, index) => {
              return <File file={file} key={index} setFiles={setFiles} setLoader={setLoader} />
            })}
          </Row>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default FileManager
