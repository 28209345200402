import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { inject } from 'mobx-react'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Input } from 'reactstrap'
import FormValidator from '../Forms/FormValidator'
import TwoFactorAuth from '../Pages/twoFactorAuth/TwoFactorAuth'
import '../Pages/login.css'
import '../Pages/twoFactorAuth.css'

const Login = inject('userStore')(({ userStore }) => {
  const [formLogin, setFormLogin] = useState({
    email: '',
    password: '',
    errors: {}
  })
  const [setup2Fa, setSetup2Fa] = useState(false)
  const [onlyVerify2Fa, setOnlyVerify2Fa] = useState(false)
  const [viaEmail, setViaEmail] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [secret, setSecret] = useState('')
  const [uri, setUri] = useState('')
  const history = useHistory()

  const validateOnChange = (event) => {
    const input = event.target
    const value = input.value
    const result = FormValidator.validate(input)

    setFormLogin((prevState) => ({
      ...prevState,
      [input.name]: value,
      errors: {
        ...prevState.errors,
        [input.name]: result
      }
    }))
  }

  const hasError = (inputName, method) => {
    return formLogin.errors && formLogin.errors[inputName] && formLogin.errors[inputName][method]
  }

  const handleSuccessfulLogin = (data) => {
    localStorage.setItem('token', data.token)
    localStorage.setItem('name', data.name)
    localStorage.setItem('surname', data.surname)
    localStorage.setItem('email', data.email)
    localStorage.setItem('function', data.function && data.function.name)

    axios.defaults.headers.common = {
      Authorization: `Bearer ${data.token}`
    }

    const decodedToken = jwt_decode(data.token)
    userStore.setAuthenticated(true)
    userStore.setUserLevel(decodedToken.user_level)
    userStore.setUserID(decodedToken.user_id)
    userStore.setUserEmail(decodedToken.email)
    userStore.setStartDate(decodedToken.start_date)
    userStore.setUserHasBirthday(decodedToken.userHasBirthday)

    const queryParams = new URLSearchParams(window.location.search)
    const redirectUrl = queryParams.get('redirect')

    if (redirectUrl) {
      window.location.href = redirectUrl
    } else {
      history.push('/dashboard')
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    setFormLogin((prevState) => ({
      ...prevState,
      errors
    }))
    setErrorMessage('')

    if (!hasError) {
      const postData = {
        email: formLogin.email,
        password: formLogin.password
      }

      axios
        .post('/auth/login', postData)
        .then((response) => {
          if (response.data.success) {
            handleSuccessfulLogin(response.data)
          } else if (response.data.shouldSetupTwoFA || response.data.shouldProvideTwoFACode) {
            setSecret(response.data.secret || '')
            setSetup2Fa(response.data.shouldSetupTwoFA || false)
            setOnlyVerify2Fa(response.data.shouldProvideTwoFACode || false)
            setViaEmail(response.data.viaEmail || false)
            setUri(response.data.uri || '')
          } else {
            setErrorMessage(response.data.message)
          }
        })
        .catch((e) => {
          const responseMessage = e.response?.data?.message
          if (responseMessage === 'Invalid 2FA token') {
            setErrorMessage('Kodi i pavlefshëm')
          } else {
            setErrorMessage('Server error')
          }
        })
    }
  }

  const onTwoFaSubmit = (token) => {
    const postData = {
      email: formLogin.email,
      password: formLogin.password,
      twoFACode: token,
      secret: secret
    }

    axios
      .post('/auth/login', postData)
      .then((response) => {
        if (response.data.success) {
          handleSuccessfulLogin(response.data)
        } else {
          setErrorMessage(response.data.message)
        }
      })
      .catch((e) => {
        const responseMessage = e.response?.data?.message
        if (responseMessage === 'Invalid 2FA token') {
          setErrorMessage('Kodi i pavlefshëm')
        } else {
          setErrorMessage('Server error')
        }
      })
  }
  const resetToLoginForm = () => {
    setSetup2Fa(false)
    setOnlyVerify2Fa(false)
    setViaEmail(false)
    setErrorMessage('')
    setSecret('')
    setUri('')
  }

  return (
    <div className='row h-100 main-container'>
      <div className='text-center col-lg-7 left-container' style={{ paddingLeft: 0 }}>
        <Link to='/login' className='text-muted'>
          <img className='block-center rounded img-fluid' src='img/logo.png' alt='Logo' />
        </Link>
      </div>
      <span className='divider' />
      <div className='col-lg-3 right-container'>
        <p className='text-center pb-3 pt-2 p'>KYÇU PËR TË VAZHDUAR.</p>

        {errorMessage && <p className='text-center text-danger'>{errorMessage}</p>}

        {!setup2Fa && !onlyVerify2Fa && (
          <form className='mb-3' name='formLogin' onSubmit={onSubmit}>
            <div className='form-group'>
              <div className='input-group with-focus'>
                <Input
                  type='email'
                  name='email'
                  className='border-right-0'
                  placeholder='Përdoruesi'
                  invalid={hasError('email', 'required')}
                  onChange={validateOnChange}
                  data-validate='["required"]'
                  value={formLogin.email}
                />
                <div className='input-group-append'>
                  <span className='input-group-text text-muted bg-transparent border-left-0'>
                    <em className='fa fa-envelope' />
                  </span>
                </div>
                {hasError('email', 'required') && <span className='invalid-feedback'>Fusha është e nevojshme</span>}
              </div>
            </div>
            <div className='form-group'>
              <div className='input-group with-focus'>
                <Input
                  type='password'
                  id='id-password'
                  name='password'
                  className='border-right-0'
                  placeholder='Fjalëkalimi'
                  invalid={hasError('password', 'required')}
                  onChange={validateOnChange}
                  data-validate='["required"]'
                  value={formLogin.password}
                  autoComplete='off'
                />
                <div className='input-group-append'>
                  <span className='input-group-text text-muted bg-transparent border-left-0'>
                    <em className='fa fa-lock' />
                  </span>
                </div>
                {hasError('password', 'required') && <span className='invalid-feedback'>Fusha është e nevojshme</span>}
              </div>
            </div>
            <div className='clearfix'>
              <div className='float-left'>
                <Link to='/recoverPassword' className='text-muted'>
                  Keni harruar passwordin?
                </Link>
              </div>
            </div>
            <button className='btn btn-block btn-info mt-3 login-btn' type='submit'>
              Kyçu
            </button>
          </form>
        )}

        {(setup2Fa || onlyVerify2Fa) && (
          <TwoFactorAuth
            setup2Fa={setup2Fa}
            viaEmail={viaEmail}
            uri={uri}
            onSubmit={onTwoFaSubmit}
            onBackToLogin={resetToLoginForm}
            onError={(message) => setErrorMessage(message)}
          />
        )}
      </div>
    </div>
  )
})

export default Login
