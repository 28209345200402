import { DOCUMENTS_API } from './consts'

export const getAwsImage = (key) => {
  return `${DOCUMENTS_API}/images/?key=${key}`
}

export const getAwsDocuments = (id, type) => {
  if (type === 'default') {
    return `${DOCUMENTS_API}/documents/default/${id}`
  } else if (type === 'new_contract') {
    return `${DOCUMENTS_API}/documents/new_contract/${id}`
  } else {
    return `${DOCUMENTS_API}/documents/${id}`
  }
}
