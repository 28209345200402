import { Viewer, Worker } from '@react-pdf-viewer/core'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import axios from 'axios'
import { version } from 'pdfjs-dist'
import { useEffect, useState, useRef } from 'react'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'

import { DOCUMENTS_API } from '../../utils/consts'
import { pinnedDocs } from './pinnedDocsMock'

const PinnedDocs = () => {
  const [selectedDoc, setSelectedDoc] = useState(null)
  const [loading, setLoading] = useState(false)

  const docCache = useRef({})

  const pageNavigationPluginInstance = pageNavigationPlugin()

  useEffect(() => {
    if (!selectedDoc?.fileKey) return

    if (docCache.current[selectedDoc.fileKey]) {
      setSelectedDoc((prev) => ({
        ...prev,
        content: docCache.current[selectedDoc.fileKey]
      }))
      return
    }

    const loadDoc = async () => {
      try {
        setLoading(true)
        const { data } = await axios.get(`${DOCUMENTS_API}/documentTemplate/${selectedDoc.fileKey}`, {
          withCredentials: false
        })
        if (data?.data?.length) {
          docCache.current[selectedDoc.fileKey] = data.data

          setSelectedDoc((prev) => ({ ...prev, content: data.data }))
        }
      } catch (error) {
        console.error('Error loading document:', error.message)
      } finally {
        setLoading(false)
      }
    }

    loadDoc()
  }, [selectedDoc?.fileKey])

  const openDoc = (doc) => {
    setSelectedDoc({
      ...doc,

      content: docCache.current[doc.fileKey] || ''
    })
  }

  const closeDoc = () => {
    setSelectedDoc(null)
  }

  const baseStyle = {
    padding: 10,
    border: '1px solid #e4eaec',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }

  return (
    <Row>
      {/* Document Viewer Modal - only renders when a document is selected */}
      <Modal size='lg' style={{ maxHeight: '80vh' }} isOpen={!!selectedDoc} toggle={closeDoc}>
        {selectedDoc && (
          <>
            <ModalHeader toggle={closeDoc}>{selectedDoc.label}</ModalHeader>
            <ModalBody>
              {loading ? (
                <div>Loading...</div>
              ) : (
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${version || '2.13.216'}/build/pdf.worker.min.js`}>
                  {selectedDoc.content ? (
                    <Viewer fileUrl={selectedDoc.content} plugins={[pageNavigationPluginInstance]} />
                  ) : (
                    <div>Gabim gjate leximit</div>
                  )}
                </Worker>
              )}
            </ModalBody>
          </>
        )}
      </Modal>

      {/* Main Documents List */}
      <Col>
        <div className='card b p-4'>
          <div className='d-flex justify-content-between mb-4'>
            <h4>Dokumente të rëndësishme:</h4>
            <span>
              <i>*Pinned</i>
            </span>
          </div>
          <Row style={{ rowGap: 10 }}>
            {pinnedDocs.map((doc, index) => (
              <Col md={6} key={index} style={{ marginBottom: 10 }}>
                {doc.items ? (
                  <div className='list-group mb0'>
                    {/* Group Header */}
                    <div
                      style={{
                        ...baseStyle,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0
                      }}
                    >
                      {doc.label}
                    </div>

                    {/* Group Items */}
                    {doc.items.map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        style={{
                          ...baseStyle,
                          borderTop: 'none',
                          borderRadius: 0,
                          borderBottomLeftRadius: itemIndex + 1 === doc.items.length ? '5px' : 0,
                          borderBottomRightRadius: itemIndex + 1 === doc.items.length ? '5px' : 0
                        }}
                      >
                        {item.label}
                        <Button onClick={() => openDoc(item)} size='xs' color='info'>
                          <span className='btn-label'>
                            <i className='fa fa-book' />
                          </span>
                          Lexo
                        </Button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div style={baseStyle}>
                    {doc.label}
                    <Button onClick={() => openDoc(doc)} size='xs' color='info'>
                      <span className='btn-label'>
                        <i className='fa fa-book' />
                      </span>
                      Lexo
                    </Button>
                  </div>
                )}
              </Col>
            ))}
          </Row>
        </div>
      </Col>
    </Row>
  )
}

export default PinnedDocs
