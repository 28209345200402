import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/Assessment'
import { Editor } from '@tinymce/tinymce-react'
import axios from 'axios'
import { saveAs } from 'file-saver'
import Lightbox from 'lightbox-react'
import 'lightbox-react/style.css'
import 'loaders.css/loaders.css'
import { inject } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/sq' // without this line it didn't work
import pubsub from 'pubsub-js'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'
import { Component } from 'react'
import { Image } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { Button, CardBody, CardHeader, Col, Collapse, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { getAwsImage } from '../../utils/awsPaths'
import FormValidator from '../Forms/FormValidator'
import ContentWrapper from '../Layout/ContentWrapper'
import '../Leaves/CustomTooltip.css'
import ModalFeed from './ModalFeed'
import NotificationBox from './NotificationBox'
import './dashboard.css'
import jwt_decode from 'jwt-decode'
import Sockette from 'sockette'
import BirthdayWish from './BirthdayWish'
import PinnedDocs from './PinnedDocs'
import { ServiceEndpoint } from './stack.json'

@inject('userStore')
class Dashboard extends Component {
  _isMounted = false

  constructor() {
    moment.locale('sq')
    super()

    this.state = {
      priorityFeed: [],
      modalPosts: false,
      feedToOpen: null,
      isImportant: true,
      dropdownOpen: false,
      dropdownOpen2: false,
      error_message: '',
      oneAtATime: true,
      accordionState: [false],
      files: [],
      siletNotifications: 0,
      dataDepartments: [],
      allDepartments: [],
      dataProjects: [],
      allProjects: [],
      dataLeaders: [],
      allLeaders: [],
      allFeeds: [],
      showDepts: [],
      formFeeds: {
        nameFeeds: '',
        descFeeds: ''
      },
      photoIndex: 0,
      isOpen: [],
      loader: false,
      check: false,
      pageId: 1,
      hasNextPage: true,
      isDisabledDepartment: false,
      isDisabledProject: false,
      isDisabledTeam: false,
      selectLoadingProject: false,
      selectLoadingDepart: false,
      selectLoadingTeam: false,
      departmentError: false,
      modal: [],
      toggle: false,
      read_by: [],
      notify: false,
      notify_all: false,
      checkBox: false,
      imageCollection: [],
      contractEndNotification: {
        data: [],
        page: 1
      },
      otherNotification: {
        data: [],
        page: 1
      },
      isPlaying: false
    }
    this.closeLightbox = this.closeLightbox.bind(this)
    this._openPhoto = this._openPhoto.bind(this)
    this._updateFeeds = this._updateFeeds.bind(this)
    this._onDelete = this._onDelete.bind(this)
    this._renderDelete = this._renderDelete.bind(this)
    this._renderExport = this._renderExport.bind(this)
    this._refreshFeed = this._refreshFeed.bind(this)
    this._refresh = this._refresh.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.validateOnChange = this.validateOnChange.bind(this)
    this.hasError = this.hasError.bind(this)
    this.videoRef = null
  }

  validateOnChange = async (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  async _callPriorityFeed(ID) {
    const params = {
      feedId: ID
    }

    const feeds = await axios.get('feeds/', { params })
    if (feeds.data.success) {
      if (this._isMounted) {
        if (feeds.data.data === null) {
          pubsub.publish('showNotification', {
            message: 'Ky postim është fshirë!',
            type: 'error'
          })
          pubsub.publish('haltClicks')
        }
        this.setState({
          priorityFeed: feeds.data.data === null ? this.state.priorityFeed : [feeds.data.data],
          modalPosts: feeds.data.data === null ? false : true
        })
      }
    }
  }

  async toggleModal(index) {
    const modalState = Array(this.state.modal.length).fill(false)
    modalState[index] = true

    this.setState({
      modal: modalState,
      toggle: !this.state.toggle
    })
  }

  toggle = () =>
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })

  toggle2 = () =>
    this.setState({
      dropdownOpen2: !this.state.dropdownOpen2
    })

  toggleAccordion = (id) => {
    let accordionState = this.state.accordionState.map((val, i) => {
      return id === i ? !val : this.state.oneAtATime ? false : val
    })
    this.setState({
      accordionState
    })
  }

  async onCheck() {
    this.setState({
      isDisabledDepartment: !this.state.isDisabledDepartment,
      isDisabledProject: !this.state.isDisabledProject,
      isDisabledTeam: !this.state.isDisabledTeam,
      dataLeaders: !this.state.isDisabledTeam ? this.state.allLeaders : [],
      dataDepartments: !this.state.isDisabledDepartment ? this.state.allDepartments : [],
      dataProjects: !this.state.isDisabledProject || this.state.user_Level === 2 ? this.state.allProjects : [],
      notify: !this.state.notify,
      notify_all: !this.state.notify_all
    })
  }

  async _refreshFeed(e) {
    e.preventDefault()
    this.setState({
      pageId: 1,
      allFeeds: []
    })
    const feeds = await axios.get('feeds/' + this.state.pageId)
    if (feeds.data.success) {
      this.setState({
        allFeeds: feeds.data.data.docs,
        hasNextPage: feeds.data.data.hasNextPage
      })
    }
  }

  async _refresh() {
    this.setState({
      pageId: 1,
      allFeeds: []
    })
    const feeds = await axios.get('feeds/' + this.state.pageId)
    if (feeds.data.success) {
      this.setState({
        allFeeds: feeds.data.data.docs,
        hasNextPage: feeds.data.data.hasNextPage
      })
    }
  }

  async _updateFeeds(e) {
    e.preventDefault()
    this.setState(
      (prevState) => ({ pageId: prevState.pageId + 1 }),
      async () => {
        try {
          const res = await axios.get('feeds/' + this.state.pageId)

          if (res.data.success) {
            const loadData = res.data.data.docs
            this.setState((prevState) => ({
              allFeeds: [...prevState.allFeeds, ...loadData],
              hasNextPage: res.data.data.hasNextPage
            }))
          } else {
            pubsub.publish('showNotification', {
              message: res.data.message,
              type: 'error'
            })
          }
        } catch (err) {
          pubsub.publish('showNotification', {
            message: 'Probleme me lidhje në rrjet',
            type: 'error'
          })
        }
      }
    )
  }

  closeLightbox() {
    this.setState({
      lightboxIsOpen: false
    })
  }

  _openPhoto(e) {
    e.preventDefault()
    this.setState({
      lightboxIsOpen: !this.state.lightboxIsOpen
    })
  }

  _badgeRender(department, project, team) {
    if (department.length < 1 && project.length < 1 && team.length < 1) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#cda400' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={<span>Projektet: Të gjitha</span>}
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#ffcc01' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>P</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#8c9298' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={<span>Departamentet: Të gjitha</span>}
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#b4b8bc' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>D</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#c38b5f' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={<span>Ekipet: Të gjitha</span>}
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#c38b5f' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>E</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (department.length > 0 && project.length < 1 && team.length < 1) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#8c9298' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Departamentet:{' '}
                {department.map((element, index) => {
                  return index % 2 ? (
                    department.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {department.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#b4b8bc' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>D</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (department.length > 0 && project.length > 0 && team.length < 1) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#cda400' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Projektet:{' '}
                {project.map((element, index) => {
                  return index % 2 ? (
                    project.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {project.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#ffcc01' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>P</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#8c9298' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Departamentet:{' '}
                {department.map((element, index) => {
                  return index % 2 ? (
                    department.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {department.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#b4b8bc' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>D</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (department.length > 0 && project.length < 1 && team.length > 0) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#8c9298' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Departamentet:{' '}
                {department.map((element, index) => {
                  return index % 2 ? (
                    department.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {department.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#b4b8bc' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>D</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#c38b5f' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Ekipet:{' '}
                {team.map((element, index) => {
                  return index % 2 ? (
                    team.length - 1 === index ? (
                      element.first_name + ' ' + element.last_name
                    ) : (
                      element.first_name + ' ' + element.last_name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {team.length - 1 === index
                        ? element.first_name + ' ' + element.last_name
                        : element.first_name + ' ' + element.last_name + ', '}{' '}
                      <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#c38b5f' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>E</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (department.length < 1 && project.length > 0 && team.length > 0) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#cda400' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Projektet:{' '}
                {project.map((element, index) => {
                  return index % 2 ? (
                    project.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {project.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#ffcc01' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>P</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#c38b5f' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Ekipet:{' '}
                {team.map((element, index) => {
                  return index % 2 ? (
                    team.length - 1 === index ? (
                      element.first_name + ' ' + element.last_name
                    ) : (
                      element.first_name + ' ' + element.last_name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {team.length - 1 === index
                        ? element.first_name + ' ' + element.last_name
                        : element.first_name + ' ' + element.last_name + ', '}{' '}
                      <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#c38b5f' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>E</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (project.length > 0 && department.length < 1 && team.length < 1) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#cda400' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Projektet:{' '}
                {project.map((element, index) => {
                  return index % 2 ? (
                    project.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {project.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#ffcc01' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>P</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (team.length > 0 && department.length < 1 && project.length < 1) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#c38b5f' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Ekipet:{' '}
                {team.map((element, index) => {
                  return index % 2 ? (
                    team.length - 1 === index ? (
                      element.first_name + ' ' + element.last_name
                    ) : (
                      element.first_name + ' ' + element.last_name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {team.length - 1 === index
                        ? element.first_name + ' ' + element.last_name
                        : element.first_name + ' ' + element.last_name + ', '}{' '}
                      <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#c38b5f' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>E</p>
            </span>
          </Tooltip>
        </div>
      )
    } else {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#cda400' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Projektet:{' '}
                {project.map((element, index) => {
                  return index % 2 ? (
                    project.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {project.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#ffcc01' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>P</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#8c9298' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Departamentet:{' '}
                {department.map((element, index) => {
                  return index % 2 ? (
                    department.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {department.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#b4b8bc' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>D</p>
            </span>
          </Tooltip>

          <Tooltip
            overlayStyle={{ backgroundColor: '#c38b5f' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Ekipet:{' '}
                {team.map((element, index) => {
                  return index % 2 ? (
                    team.length - 1 === index ? (
                      element.first_name + ' ' + element.last_name
                    ) : (
                      element.first_name + ' ' + element.last_name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {team.length - 1 === index
                        ? element.first_name + ' ' + element.last_name
                        : element.first_name + ' ' + element.last_name + ', '}{' '}
                      <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#c38b5f' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>E</p>
            </span>
          </Tooltip>
        </div>
      )
    }
  }

  readUsers(feed_id) {
    axios
      .get(`feeds/${feed_id}/readers`)
      .then(async (res) => {
        if (res.data.success) {
          this.setState({
            read_by: res.data.data.readers.read_by
          })
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  onDrop = (files) => {
    this.setState({ files: [...this.state.files, ...files] })
  }

  onMessageReceive = (e) => {
    const newData = JSON.parse(e.data)
    if (newData.type === 'END_OF_CONTRACT' || newData.type === 'LAST_DAY_ON_WORK' || newData.type === 'END_OF_PROBATION') {
      this.setState({
        contractEndNotification: {
          ...this.state.contractEndNotification,
          data: [newData, ...this.state.contractEndNotification.data]
        }
      })
    } else {
      this.setState({
        otherNotification: { ...this.state.otherNotification, data: [newData, ...this.state.otherNotification.data] }
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }
  fetchOtherNotifications() {
    axios.get(`notification/user/me/10/${this.state.otherNotification.page}?filter=All`).then(({ data }) => {
      if (data.success && data.data.length) {
        this.setState({
          otherNotification: { ...this.state.otherNotification, data: [...this.state.otherNotification.data, ...data.data] }
        })
      }
    })
  }

  fetchContractEndNotification() {
    const user_Level = this.props.userStore.userLevel
    const hasAccessToContractNotifications = [1, 5, 6].includes(user_Level)
    if (hasAccessToContractNotifications) {
      axios.get(`notification/user/me/contract_info/10/${this.state.contractEndNotification.page}?filter=All`).then(({ data }) => {
        if (data.success && data.data.length) {
          this.setState({
            contractEndNotification: {
              ...this.state.contractEndNotification,
              data: [...this.state.contractEndNotification.data, ...data.data]
            }
          })
        }
      })
    }
  }

  async componentDidMount() {
    this._isMounted = true
    const user_Level = this.props.userStore.userLevel
    const user_id = this.props.userStore.user_id
    const token = localStorage.getItem('token')
    const tokenJWT = jwt_decode(token)
    const departmentJWT = tokenJWT.department
    const projectJWT = tokenJWT.project

    pubsub.subscribe('openFeed', (_, data) => {
      this._callPriorityFeed(data.feedID)
      this.setState({
        modalPosts: true,
        feedToOpen: data.feedID
      })
    })

    if (tokenJWT) {
      new Sockette(`${ServiceEndpoint}/?userId=${tokenJWT.user_id}`, {
        timeout: 5e3,
        onopen: () => {},
        maxAttempts: 3,
        onmessage: this.onMessageReceive,
        onclose: () => {}
      })
    }

    const [departments, feeds, projects, completedUser] = await Promise.allSettled([
      axios.get('departments'),
      axios.get('feeds/' + this.state.pageId),
      axios.get('projects'),
      axios.get(`users/${user_id}`),
      this.fetchOtherNotifications(),
      this.fetchContractEndNotification()
    ])

    try {
      if (projects.value.data.success) {
        var allProjects = []
        projects.value.data.data.forEach((project) => {
          allProjects.push({
            value: project._id,
            label: project.name
          })
        })
        if (this._isMounted) {
          await this.setState({ allProjects: allProjects })
        }

        if (user_Level === 3) {
          const updateArrayProject = this.state.allProjects.filter((value) => projectJWT.includes(value.value))
          if (this._isMounted) {
            this.setState({
              allProjects: updateArrayProject,
              isDisabledProject: true,
              isDisabledDepartment: true,
              isDisabledTeam: true,
              dataProjects: updateArrayProject
            })
          }
        }
      }
      if (departments.value.data.success && this._isMounted) {
        const allDepartments = departments.value.data.data.map((department) => ({
          value: department._id,
          label: department.name,
          project_id: department.project._id
        }))

        await this.setState({ allDepartments })
      }

      if (user_Level === 3 && this._isMounted) {
        const updateArrayDepartment = this.state.allDepartments.filter((value) => departmentJWT.includes(value.value))

        const user_id = tokenJWT.user_id
        const allLeaders = [
          {
            value: user_id,
            label: `${localStorage.getItem('name')} ${localStorage.getItem('surname')}`
          }
        ]

        this.setState({
          allDepartments: updateArrayDepartment,
          isDisabledProject: true,
          isDisabledDepartment: true,
          isDisabledTeam: true,
          dataDepartments: updateArrayDepartment,
          dataLeaders: allLeaders
        })
      }

      if ([1, 2, 5, 6].includes(user_Level)) {
        try {
          const teamLeaders = await axios.get('users/teamleaders')

          if (teamLeaders.data.success) {
            const allLeaders = teamLeaders.data.data.map((leader) => ({
              value: leader._id,
              label: `${leader.first_name} ${leader.last_name}`
            }))

            if (this._isMounted) {
              this.setState({ allLeaders })
            }
          }
        } catch (error) {
          console.error('Ndodhi nje gabim gjate marrjes se TeamLeader-s:', error)
        }
      }

      if (feeds.value.data.success && this._isMounted) {
        const modalState = Array(feeds.value.data.data.docs.length).fill(false)

        this.setState({
          modal: modalState,
          allFeeds: feeds.value.data.data.docs,
          hasNextPage: feeds.value.data.data.hasNextPage
        })
      }
    } catch (e) {
      if (this._isMounted) {
        this.setState({ error_message: 'Error fetching data from the server' })
      }
    }
  }
  handleChangeDepar = async (departments) => {
    await this.setState({
      dataDepartments: departments
    })

    if (this.state.dataDepartments.length > 0) {
      try {
        this.setState({
          selectLoadingDepart: true
        })

        const changedDepartments = this.state.dataDepartments.map((element) => element.value)

        const response = await axios.get('users/teamleaders', {
          params: {
            departments: changedDepartments
          }
        })

        if (response.data.success) {
          const allLeaders = response.data.data.map((leader) => ({
            value: leader._id,
            label: `${leader.first_name} ${leader.last_name}`
          }))

          this.setState({
            allLeaders,
            dataLeaders: allLeaders
          })
        }
      } catch (error) {
        console.error('Ndodhi një gabim gjatë kërkimit të teamleader-s:', error)
      } finally {
        this.setState({
          selectLoadingDepart: false
        })
      }
    }
  }

  handleChangeProj = async (projects) => {
    const { userLevel } = this.props.userStore
    const { allDepartments } = this.state

    await this.setState({
      dataProjects: projects
    })

    if ([1, 5, 6, 2].includes(userLevel)) {
      const changedProjects = this.state.dataProjects.map((element) => element.value)

      const updateArrayDepartment = allDepartments.filter((department) =>
        changedProjects.some((project) => department.project_id === project)
      )

      this.setState({
        isDisabledProject: false,
        dataDepartments: updateArrayDepartment,
        showDepts: updateArrayDepartment
      })
    }
  }

  handleChangeLeader = (leaders) => {
    this.setState({
      dataLeaders: leaders
    })
  }

  handleEditorChange = (content, editor) => {
    const data = content

    this.setState(({ formFeeds }) => ({
      formFeeds: {
        ...formFeeds,
        descFeeds: data
      }
    }))
  }

  _removeFromFiles = (e, index) => {
    e.preventDefault()
    this.setState({
      files: this.state.files.filter((_, i) => i != index)
    })
  }

  validateOnChange = async (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)
    await this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()
    const form = e.target
    const inputs = [...form.elements].filter((i) => {
      if (i.name) {
        return ['INPUT', 'SELECT'].includes(i.nodeName)
      } else {
        return false
      }
    })
    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (!hasError) {
      const token = localStorage.getItem('token')
      const tokeni = jwt_decode(token)
      const user_Level = this.props.userStore.userLevel

      const leaderTeam = user_Level === 3 ? tokeni.user_id : null
      const target_team = user_Level === 3 ? [leaderTeam] : this.state.dataLeaders.map((target) => target.value)

      const formData = {
        title: this.state.formFeeds.nameFeeds,
        description: this.state.formFeeds.descFeeds,
        target_project: this.state.dataProjects.map((target) => target.value),
        target_department: this.state.dataDepartments.map((target) => target.value),
        target_team: target_team,
        notify_all: this.state.notify_all
      }

      await new Promise((resolve) => {
        this.setState(
          {
            loader: true,
            departmentError: false,
            notify: formData.target_project.length !== 0 || formData.target_department.length !== 0 || formData.target_team.length !== 0
          },
          resolve
        )
      })

      const config = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }

      if (!this.state.departmentError) {
        if (this.state.notify) {
          axios
            .post('feeds', formData)
            .then((res) => {
              if (res.data.success) {
                const setStateData = {
                  pageId: 1,
                  accordionState: [false],
                  formFeeds: { nameFeeds: '', descFeeds: '' }
                }

                if ([1, 2, 5, 6].includes(user_Level)) {
                  setStateData.dataLeaders = []
                  setStateData.dataProjects = []
                  setStateData.dataDepartments = []
                  document.getElementById('checkbox').checked = this.state.checkBox
                }

                this.setState(setStateData, () => {
                  this.setState({
                    isDisabledDepartment: false,
                    isDisabledProject: false,
                    isDisabledTeam: false,
                    notify_all: false
                  })

                  const dataFiles = new FormData()
                  dataFiles.append('feedId', res.data.data._id)
                  this.state.files.forEach((file) => {
                    dataFiles.append('files', file, file.name)
                  })
                  if (this.state.files.length > 0) {
                    axios
                      .post('feeds/filesUpload', dataFiles, config)
                      .then((res) => {
                        if (res.data.success) {
                          this.setState({ files: [] })
                          this._reloader(this.state.pageId)
                        }
                      })
                      .catch((err) => {})
                  } else {
                    this._reloader(this.state.pageId)
                  }
                })
              } else {
                this.setState({ loader: false })
                pubsub.publish('showNotification', { message: res.data.data, type: 'error' })
              }
            })
            .catch((err) => {
              this.setState({ loader: false })
              pubsub.publish('showNotification', { message: 'Probleme me lidhje në rrjet', type: 'error' })
            })
        } else {
          pubsub.publish('showNotification', { message: 'Specifikoni target grupin', type: 'error' })
          this.setState({ loader: false })
        }
      }
    }
  }

  _reloader(pageId) {
    axios
      .get('feeds/' + pageId)
      .then((res) => {
        this.setState({
          allFeeds: res.data.data.docs,
          formFeeds: {
            nameFeeds: '',
            descFeeds: ''
          },
          loader: false
        })
      })
      .catch((err) => {})
  }

  _deleter(storyId) {
    this.setState({
      allFeeds: this.state.allFeeds.filter((e) => {
        return e._id != storyId
      })
    })
  }

  _onDelete = (e, id) => {
    e.preventDefault()

    Swal.fire({
      title: 'A jeni i sigurt?',
      text: `Nëse fshini postin, të gjitha të dhënat do të fshihen!`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Po, fshije.',
      cancelButtonText: 'Jo.'
    }).then((result) => {
      if (result.value) {
        axios
          .delete('feeds/' + id)
          .then((response) => {
            if (response.data.success) {
              this._deleter(id)
              pubsub.publish('showNotification', {
                message: 'Postimi është fshirë me sukses.',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: 'Gabim në server. Të dhënat nuk janë fshirë',
                type: 'error'
              })
            }
          })
          .catch((e) => {
            pubsub.publish('showNotification', {
              message: 'Gabim në server. Të dhënat nuk janë fshirë',
              type: 'error'
            })
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        pubsub.publish('showNotification', {
          message: 'Fshirja eshte anuluar',
          type: 'error'
        })
      }
    })
  }

  _renderImage(collection, feedId) {
    if (collection.length > 5) {
      return (
        <div>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            <img
              onClick={() =>
                this.setState({
                  isOpen: { ...this.state.isOpen, [feedId]: true },
                  photoIndex: 0
                })
              }
              className='img-thumbnail'
              style={{ objectFit: 'cover', width: '100%', maxHeight: 400 }}
              src={collection[0]}
              alt='Demo'
            />
          </a>

          <Row style={{ marginTop: 10 }}>
            {[...Array(3)].map((i, x) => {
              return (
                <Col key={x} xs={4} md={3}>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <Image
                      style={{ height: 120, objectFit: 'cover', width: '100%' }}
                      src={collection[x + 1]}
                      thumbnail
                      onClick={() =>
                        this.setState({
                          isOpen: { ...this.state.isOpen, [feedId]: true },
                          photoIndex: x + 1
                        })
                      }
                    />
                  </a>
                </Col>
              )
            })}
            <Col xs={4} md={3}>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                <Image
                  style={{ height: 120, objectFit: 'cover', width: '100%' }}
                  src='/img/more_image.png'
                  thumbnail
                  onClick={() =>
                    this.setState({
                      isOpen: { ...this.state.isOpen, [feedId]: true },
                      photoIndex: 4
                    })
                  }
                />
              </a>
            </Col>
          </Row>
        </div>
      )
    } else {
      return (
        <div>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            <img
              onClick={() => {
                this.setState({
                  isOpen: { ...this.state.isOpen, [feedId]: true },
                  photoIndex: 0
                })
              }}
              className='img-thumbnail'
              style={{ objectFit: 'cover', width: '100%', maxHeight: 400 }}
              src={collection[0]}
              alt='Demo'
            />
          </a>

          <Row style={{ marginTop: 10 }}>
            {[...Array(collection.length - 1)].map((i, x) => {
              return (
                <Col key={x} xs={4} md={3}>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <Image
                      style={{ height: 120, objectFit: 'cover', width: '100%' }}
                      src={collection[x + 1]}
                      thumbnail
                      onClick={() =>
                        this.setState({
                          isOpen: { ...this.state.isOpen, [feedId]: true },
                          photoIndex: x + 1
                        })
                      }
                    />
                  </a>
                </Col>
              )
            })}
          </Row>
        </div>
      )
    }
  }

  _renderExport(user_Level, feed_id) {
    if ([1, 2, 3, 5, 6].includes(user_Level)) {
      return (
        <Tooltip
          overlayStyle={{ backgroundColor: '#cecece' }}
          onClick={(e) => {
            e.preventDefault()
          }}
          placement='top'
          overlay={<span>Shkarko statistika mbi lexueshmërinë</span>}
        >
          <IconButton
            style={{ padding: '5px' }}
            aria-label={'Shkarko CSV'}
            onClick={() => {
              this._getExport(feed_id)
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      )
    }
  }

  _getExport(feedID) {
    axios
      .get(`feeds/${feedID}/exportReads`, { responseType: 'blob' })
      .then((response) => {
        if (response.headers['content-type'] === 'text/x-csv') {
          saveAs(response.data, 'Read_By.csv')
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  _renderDelete(user_Level, userID, created_by_id, feed_id) {
    if (user_Level === 2 && userID === created_by_id) {
      return (
        <Button
          style={{ backgroundColor: '#f05050', color: 'white' }}
          onClick={(e) => {
            this._onDelete(e, feed_id)
          }}
        >
          Fshij
        </Button>
      )
    } else if (user_Level === 3 && userID === created_by_id) {
      return (
        <Button
          style={{ backgroundColor: '#f05050', color: 'white' }}
          onClick={(e) => {
            this._onDelete(e, feed_id)
          }}
        >
          Fshij
        </Button>
      )
    } else if (user_Level === 1 || user_Level === 5 || user_Level === 6) {
      return (
        <Button
          style={{ backgroundColor: '#f05050', color: 'white' }}
          onClick={(e) => {
            this._onDelete(e, feed_id)
          }}
        >
          Fshij
        </Button>
      )
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.otherNotification.page != this.state.otherNotification.page) {
      this.fetchOtherNotifications()
    }
    if (prevState.contractEndNotification.page != this.state.contractEndNotification.page) {
      this.fetchContractEndNotification()
    }
  }

  transform = (node) => {
    if (node.type === 'tag' && node.name === 'iframe') {
      return (
        <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
          <iframe {...node.attribs} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
        </div>
      )
    }
  }

  render() {
    const { photoIndex, isOpen, hasNextPage } = this.state
    const userID = this.props.userStore.user_id
    const user_Level = this.props.userStore.userLevel
    const user_email = this.props.userStore.email

    const hasAccessToContractNotifications = [1, 5, 6].includes(user_Level)

    if (!this.props.userStore.userHasBirthday) {
      sessionStorage.removeItem(user_email)
    }
    return (
      <>
        <ContentWrapper>
          <div>
            {
              <a data-keyboard='false' data-backdrop='static'>
                {' '}
                {this.state.allFeeds.length != 0 && (
                  <ModalFeed
                    userID={userID}
                    user_level={user_Level}
                    dataFeeds={this.state.allFeeds}
                    next={this.state.hasNextPage}
                    refresh={this._refresh}
                  />
                )}
              </a>
            }
          </div>
          <Row style={{ flexDirection: 'row-reverse' }}>
            {/* START dashboard sidebar */}
            <Col xl={{ size: 6, offset: 0 }} className='sticky-right notifications'>
              <NotificationBox
                data={this.state.otherNotification}
                setPage={() =>
                  this.setState({
                    otherNotification: {
                      ...this.state.otherNotification,
                      page: this.state.otherNotification.page + 1
                    }
                  })
                }
                type='other'
              />

              {hasAccessToContractNotifications && (
                <NotificationBox
                  contracts
                  data={this.state.contractEndNotification}
                  setPage={() =>
                    this.setState({
                      contractEndNotification: {
                        ...this.state.contractEndNotification,
                        page: this.state.contractEndNotification.page + 1
                      }
                    })
                  }
                  type='contract'
                />
              )}
            </Col>
            <Col xl={6}>
              <PinnedDocs />
              {/* Birthday wish Modal */}
              {this.props.userStore.userHasBirthday && (
                <BirthdayWish name={localStorage.getItem('name')} surname={localStorage.getItem('surname')} user_email={user_email} />
              )}
              {/* Work Location Modal */}
              {user_Level === 4 || user_Level === 7 || user_Level === 8 ? null : (
                <Row>
                  <Col xl={{ size: 12, offset: 0 }} offset='true'>
                    <form onSubmit={this.onSubmit} name='formFeeds'>
                      <div className={`card b ${this.state.loader ? 'whirl standard' : null}`}>
                        <div className='card-body'>
                          <div className='mb-2 mt-2'>
                            <Input
                              type='text'
                              placeholder='Titulli'
                              name='nameFeeds'
                              value={this.state.formFeeds.nameFeeds}
                              onChange={this.validateOnChange}
                              invalid={this.hasError('formFeeds', 'nameFeeds', 'required')}
                              data-validate='["required"]'
                            />
                            <span className='invalid-feedback'>Fusha është e nevojshme</span>
                          </div>
                          <div className='mb-2 mt-2' method='post' action=''>
                            <style>
                              {`
                                .tox-promotion-link {
                                  display: none !important;
                                }

                                .tox-statusbar__text-container {
                                  display: none !important;
                                }

                                .tox .tox-statusbar {
                                  justify-content:flex-end !important;
                                }
                              `}
                              ,
                            </style>
                            <Editor
                              initialValue=''
                              value={this.state.formFeeds.descFeeds}
                              init={{
                                license_key: 'gpl',
                                height: 200,
                                menubar: 'edit view insert format table tc help',
                                plugins: `
                                  advlist autolink lists link charmap preview
                                  searchreplace visualblocks fullscreen
                                  insertdatetime media table help wordcount`,
                                automatic_uploads: false,
                                toolbar: `undo redo blocks bold italic backcolor 
                                  alignleft aligncenter alignright | alignjustify
                                  bullist numlist outdent indent removeformat help`
                              }}
                              onEditorChange={this.handleEditorChange}
                            />
                          </div>
                          {this.props.userStore.userLevel !== 3 && (
                            <div className='card b mb-3'>
                              <CardHeader onClick={() => this.toggleAccordion(0)}>
                                <div className='d-flex justify-content-between'>
                                  <span color='info' className='btn-labeled'>
                                    <span>Specifiko target grupin</span>
                                  </span>
                                  <div>
                                    <i className='fas fa-angle-down' />
                                  </div>
                                </div>
                              </CardHeader>

                              <Collapse isOpen={this.state.accordionState[0]}>
                                <CardBody id='collapse01' name='collapse'>
                                  <div className='mb-2 mt-2'>
                                    <Select
                                      isDisabled={this.state.isDisabledProject}
                                      placeholder='Selekto Projektin'
                                      name='projects'
                                      isMulti
                                      isLoading={this.state.selectLoadingProject}
                                      simpleValue
                                      value={this.state.isDisabledProject ? [] : this.state.dataProjects}
                                      options={this.state.allProjects}
                                      onChange={this.handleChangeProj}
                                    />
                                  </div>
                                  <div className='mb-2 mt-2'>
                                    <Select
                                      isDisabled={this.state.isDisabledDepartment}
                                      placeholder='Selekto Departamentin'
                                      name='departments'
                                      isMulti
                                      simpleValue
                                      isLoading={this.state.selectLoadingDepart}
                                      value={!this.state.isDisabledDepartment ? this.state.dataDepartments : []}
                                      options={this.state.allDepartments}
                                      onChange={this.handleChangeDepar}
                                    />
                                  </div>
                                  <div className='mb-2 mt-2'>
                                    <Select
                                      isDisabled={this.state.isDisabledTeam}
                                      placeholder='Selekto Liderin'
                                      name='leaders'
                                      isMulti
                                      simpleValue
                                      isLoading={this.state.selectLoadingTeam}
                                      value={!this.state.isDisabledTeam ? this.state.dataLeaders : []}
                                      options={this.state.allLeaders}
                                      onChange={this.handleChangeLeader}
                                    />
                                  </div>
                                </CardBody>
                              </Collapse>
                            </div>
                          )}

                          <div className='checkbox c-checkbox'>
                            <div className='checkbox c-checkbox'>
                              {this.props.userStore.userLevel !== 3 && (
                                <label>
                                  <Input id='checkbox' onChange={() => this.onCheck()} name='all' type='checkbox' />
                                  <span className='fa fa-check' /> Të gjithë
                                </label>
                              )}
                            </div>
                          </div>

                          <div className='clearfix' style={{ lineHeight: 0 }}>
                            <div className='float-left'>
                              <ul className='nav nav-pills'>
                                <Dropzone onDrop={this.onDrop}>
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div
                                        {...getRootProps({
                                          className: 'dropzone'
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <Tooltip placement='top' overlay={<span>Ngarko file</span>}>
                                          <li className='nav-item'>
                                            <a className='nav-link' style={{ paddingLeft: 0 }} onClick={(e) => e.preventDefault()} href='#'>
                                              <em style={{ color: '#3a3f51' }} className='fa fa-file fa-lg'></em>
                                            </a>
                                          </li>
                                        </Tooltip>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                                {this.state.files.length > 0 && (
                                  <li className='nav-item'>
                                    {this.state.files.map((file, index) => {
                                      if (file.type.includes('image')) {
                                        return (
                                          <Tooltip key={index} placement='top' overlay={<span>{file.name}</span>}>
                                            <div className='uploadPreview'>
                                              <img src={URL.createObjectURL(file)} />
                                              <a
                                                onClick={(e) => {
                                                  this._removeFromFiles(e, index)
                                                }}
                                                className='uploadClose'
                                                href='#'
                                              >
                                                <em className='fa fa-times-circle' />
                                              </a>
                                            </div>
                                          </Tooltip>
                                        )
                                      } else {
                                        return (
                                          <Tooltip key={index} placement='top' overlay={<span>{file.name}</span>}>
                                            <div className='uploadPreview'>
                                              <img src='img/file_icon.png' />
                                              <a
                                                onClick={(e) => {
                                                  this._removeFromFiles(e, index)
                                                }}
                                                className='uploadClose'
                                                href='#'
                                              >
                                                <em className='fa fa-times-circle' />
                                              </a>
                                            </div>
                                          </Tooltip>
                                        )
                                      }
                                    })}
                                  </li>
                                )}
                              </ul>
                            </div>
                            <div className='float-right'>
                              <button type='submit' className='btn btn-info'>
                                Posto
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Col>
                </Row>
              )}

              {this.state.siletNotifications ? (
                <div className='text-center my-3'>
                  <Button color='primary' size='lg' className='btn-oval' onClick={this._refreshFeed}>
                    {this.state.siletNotifications} postime të reja
                  </Button>
                </div>
              ) : null}

              <Row>
                <Col xl={{ size: 12, offset: 0 }}>
                  {this.state.allFeeds.map((feed, index) => {
                    const read_by = feed.read_by
                    const files = feed.files
                    var string = ''
                    const totalRead = feed.totalRead
                    const totalNotRead = feed.totalNotRead
                    const imageCollection = []
                    read_by.map((feed, index) => {
                      if (index <= 5) {
                        string = string + feed.first_name + ' ' + feed.last_name + '\n'
                        if (index === 5) string = string + '...'
                      }
                    })

                    files.map((file, index) => {
                      if (file.mimetype.includes('image')) {
                        imageCollection.push(getAwsImage(file.url))
                      }
                    })
                    return (
                      <div key={index} className='card b'>
                        <div className='card-header'>
                          {(user_Level === 1 || user_Level === 6 || user_Level === 5) && (
                            <div className='float-right'>
                              <ul className='nav nav-pills align-items-center'>
                                {this._badgeRender(feed.target_department, feed.target_project, feed.target_team)}
                                {this._renderDelete(user_Level, userID, feed?.created_by?._id, feed._id)}
                              </ul>
                            </div>
                          )}
                          <div className='media mt-0'>
                            <img className='mr-2 img-thumbnail rounded-circle thumb32' src='img/blank_profile.png' />
                            <div className='media-body'>
                              <h4 className='m-0 text-bold'>{feed?.created_by?.first_name + ' ' + feed?.created_by?.last_name}</h4>
                              <div className='text-sm text-muted'>{moment(feed.posted_date).fromNow()}</div>
                            </div>
                          </div>
                        </div>
                        <div className='card-body'>
                          <div className='row' style={{ marginTop: -10 }}>
                            <div className='col-xl-12'>
                              <h4>{feed.title}</h4>
                              <>{ReactHtmlParser(feed.description, { transform: this.transform })}</>
                              {imageCollection.length > 0 ? this._renderImage(imageCollection, feed._id) : null}
                            </div>
                            <div>
                              {isOpen[feed._id] && (
                                <Lightbox
                                  mainSrc={imageCollection[photoIndex]}
                                  nextSrc={imageCollection[(photoIndex + 1) % imageCollection.length]}
                                  prevSrc={imageCollection[(photoIndex + imageCollection.length - 1) % imageCollection.length]}
                                  onCloseRequest={() =>
                                    this.setState({
                                      isOpen: {
                                        ...this.state.isOpen,
                                        [feed._id]: false
                                      }
                                    })
                                  }
                                  onMovePrevRequest={() =>
                                    this.setState({
                                      photoIndex: (photoIndex + imageCollection.length - 1) % imageCollection.length
                                    })
                                  }
                                  onMoveNextRequest={() =>
                                    this.setState({
                                      photoIndex: (photoIndex + 1) % imageCollection.length
                                    })
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className='card-body'>
                          <div style={{ marginTop: -25 }}>
                            <div className='float-left'>
                              <ul className='nav nav-pills'>
                                {files.length > 0 && (
                                  <li className='nav-item'>
                                    {files.map((file, index) => {
                                      if (!file.mimetype.includes('image')) {
                                        return (
                                          <div key={index}>
                                            <em className='fa fa-download' />
                                            <Button
                                              onClick={() => {
                                                try {
                                                  saveAs(getAwsImage(file.url), file.name)
                                                } catch (e) {
                                                  console.log('error', e)
                                                  pubsub.publish('showNotification', {
                                                    message: 'Gabim gjatë shkarkimit të file',
                                                    type: 'error'
                                                  })
                                                }
                                              }}
                                              key={index}
                                              style={{ marginLeft: 10 }}
                                              className='uploadDownload'
                                            >
                                              {file.name}
                                            </Button>
                                          </div>
                                        )
                                      }
                                    })}
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        {this.state.toggle && (
                          <Modal toggle={this.toggleModal} className='modal-dialog-centered' isOpen={this.state.modal[index]}>
                            <ModalHeader toggle={this.toggleModal}>
                              <p style={{ fontSize: '14px', margin: '0px' }}>{'Lexuar: ' + totalRead + ' '}</p>
                              <p style={{ fontSize: '14px', margin: '0px' }}>{'Nuk e kanë lexuar: ' + totalNotRead + ' '}</p>
                            </ModalHeader>
                            <ModalBody className={this.state.read_by.length >= 8 ? 'modal-body-scrollable' : 'p-0'}>
                              <div className='col-lg-12'>
                                <Row className='justify-content-center align-self-center'>
                                  <Col lg={11}>
                                    {this.state.read_by.map((file, index) => {
                                      return (
                                        <div
                                          className={user_Level === 1 || user_Level === 5 || user_Level === 6 ? 'modal-text' : 'text'}
                                          key={index}
                                          to={user_Level === 1 || user_Level === 5 || user_Level === 6 ? `/editUsers/${file._id}` : '#'}
                                        >
                                          <div className='media bb p-2' key={index}>
                                            <img
                                              style={{ objectFit: 'cover' }}
                                              className='mr-2 rounded-circle thumb32'
                                              src={'img/blank_profile.png'}
                                              alt='Avatar'
                                            />
                                            <div className='media-body' style={{ paddingTop: '0.3rem' }}>
                                              <Link
                                                className={user_Level === 1 || user_Level === 5 || user_Level === 6 ? 'modal-text' : 'text'}
                                                key={index}
                                                to={
                                                  user_Level === 1 || user_Level === 5 || user_Level === 6 ? `/editUsers/${file._id}` : '#'
                                                }
                                              >
                                                {file.first_name + ' ' + file.last_name}
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </Col>
                                </Row>
                              </div>
                            </ModalBody>
                          </Modal>
                        )}
                        {(user_Level === 1 || user_Level === 6) && (
                          <div className='card-body'>
                            <ul className='nav nav-pills align-items-center float-right inline'>
                              {this._renderExport(user_Level, feed._id)}
                            </ul>
                            <div
                              className='float-right'
                              onClick={(e) => read_by.length - 5 > 0 && this.toggleModal(index) && this.readUsers(feed._id)}
                              data-tooltip={string !== '' ? string : null}
                              data-tooltip-position='top'
                            >
                              {read_by.map((file, index) => {
                                if (index < 5) {
                                  return (
                                    <a className='inline' key={index}>
                                      <img
                                        style={{ objectFit: 'cover' }}
                                        className='rounded-circle thumb24 mr-1'
                                        src={'img/blank_profile.png'}
                                        alt='project member'
                                      />
                                    </a>
                                  )
                                }
                              })}
                              <a
                                style={{
                                  height: '19px',
                                  verticalAlign: 'bottom'
                                }}
                                className='inline'
                              >
                                <strong>{read_by.length - 5 > 0 ? '...' : ''}</strong>
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })}
                  <div className='text-center my-3'>
                    {hasNextPage ? (
                      <Button className='btn btn-secondary btn-oval btn-lg' onClick={this._updateFeeds}>
                        Më shumë
                      </Button>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Col>
            {/* END dashboard main content */}
          </Row>
        </ContentWrapper>
      </>
    )
  }
}

export default Dashboard
