import axios from 'axios'
import Lightbox from 'lightbox-react'
import moment from 'moment'
import pubsub from 'pubsub-js'
import Tooltip from 'rc-tooltip'
import React from 'react'
import { Button, Image } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { getAwsImage } from '../../utils/awsPaths'

class ModalFeed extends React.Component {
  constructor() {
    super()
    this.state = {
      modal: false,
      data: [],
      selectedIndex: 0,
      isOpen: [],
      photoIndex: 0,
      videoWatched: false,
      isPlaying: false // New state for tracking if video is playing
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.toggleRead = this.toggleRead.bind(this)
    this._onDelete = this._onDelete.bind(this)
    this.handleVideoMessage = this.handleVideoMessage.bind(this)
    this.playVideo = this.playVideo.bind(this) // Binding for new playVideo method
    this.videoRef = null // Reference to the video element
  }

  // Add this method to play the video
  playVideo = () => {
    if (this.videoRef) {
      // Create a user interaction context for iOS
      const playPromise = this.videoRef.play()

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            this.setState({ isPlaying: true })
            console.log('Video playing successfully')
          })
          .catch((error) => {
            console.error('Video play failed:', error)

            // Special handling for iOS - try again with muted first then unmute
            if (error.name === 'NotAllowedError') {
              this.videoRef.muted = true
              this.videoRef
                .play()
                .then(() => {
                  // Now that we have user interaction, we can unmute
                  this.videoRef.muted = false
                  this.setState({ isPlaying: true })
                  console.log('Video playing with iOS workaround')
                })
                .catch((err) => {
                  console.error('iOS workaround failed:', err)
                  pubsub.publish('showNotification', {
                    message: 'Video nuk mund të luhet. Ju lutemi provoni përsëri.',
                    type: 'error'
                  })
                })
            } else {
              pubsub.publish('showNotification', {
                message: 'Video nuk mund të filloje. Ju lutemi provoni përsëri.',
                type: 'error'
              })
            }
          })
      }
    } else {
      console.error('Video reference not found')

      // Try to find the video element if ref failed
      const videoElement = document.querySelector('video')
      if (videoElement) {
        this.videoRef = videoElement
        this.playVideo() // Try again with the found element
      } else {
        pubsub.publish('showNotification', {
          message: 'Video nuk mund të gjendet. Ju lutemi rifreskoni faqen.',
          type: 'error'
        })
      }
    }
  }

  transform = (node) => {
    if (node.type === 'tag' && node.name === 'iframe') {
      const videoSrc = node.attribs.src
      return (
        <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
          <video
            ref={(video) => (this.videoRef = video)}
            style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
            onEnded={() => this.setState({ videoWatched: true })}
            onContextMenu={(e) => e.preventDefault()}
            controlsList='nodownload noplaybackrate'
            disablePictureInPicture
            disableRemotePlayback
            playsInline // Add playsInline attribute for iOS
          >
            <source src={videoSrc} type='video/mp4' />
            Your browser does not support HTML5 video.
          </video>
          {!this.state.isPlaying && (
            <div
              onClick={this.playVideo}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                cursor: 'pointer',
                zIndex: 10 // Add zIndex to make sure it's above the video
              }}
            >
              <div
                style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <i className='fa fa-play' style={{ fontSize: '40px', color: '#5d9cec' }}></i>
              </div>
            </div>
          )}
        </div>
      )
    }
  }

  _onDelete = (e, id) => {
    e.preventDefault()
    Swal.fire({
      title: 'A jeni i sigurt?',
      text: `Nëse fshini postin, të gjitha të dhënat do të fshihen!`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Po, fshije.',
      cancelButtonText: 'Jo.'
    }).then((result) => {
      if (result.value) {
        axios
          .delete('feeds/' + id)
          .then((response) => {
            this.setState({
              modal: false
            })
            if (response.data.success) {
              this._deleter(id)
              pubsub.publish('showNotification', {
                message: 'Postimi është fshirë me sukses.',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: 'Gabim në server. Të dhënat nuk janë fshirë',
                type: 'error'
              })
            }
          })
          .catch((e) => {
            pubsub.publish('showNotification', {
              message: 'Gabim në server. Të dhënat nuk janë fshirë',
              type: 'error'
            })
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        pubsub.publish('showNotification', {
          message: 'Fshirja eshte anuluar',
          type: 'error'
        })
      }
    })
  }

  _deleter(storyId) {
    this.toggleNext()
    this.setState({
      data: this.state.data.filter((e) => {
        return e._id != storyId
      })
    })
    this.props.refresh()
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal
    }))
  }

  toggleRead() {
    const hasVideo = this.state.data[this.state.selectedIndex]?.description?.includes('<iframe')
    if (hasVideo && !this.state.videoWatched) {
      return pubsub.publish('showNotification', {
        message: 'Ju duhet te shikoni videon deri ne fund',
        type: 'error'
      })
    }
    if (this.state.data[this.state.selectedIndex]._id !== null)
      axios.patch(`/feeds/${this.state.data[this.state.selectedIndex]._id}/read`).then((res) => {})
    if (this.state.selectedIndex === this.state.data.length - 1) {
      this.setState((prevState) => ({
        modal: !prevState.modal
      }))
      return
    }

    this.setState((prevState) => ({
      selectedIndex: prevState.selectedIndex + 1,
      videoWatched: false,
      isPlaying: false // Reset isPlaying when moving to next feed
    }))
  }

  _badgeRender(department, project, team) {
    if (department.length < 1 && project.length < 1 && team.length < 1) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#cda400' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={<span>Projektet: Të gjitha</span>}
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#ffcc01' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>P</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#8c9298' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={<span>Departamentet: Të gjitha</span>}
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#b4b8bc' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>D</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#c38b5f' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={<span>Ekipet: Të gjitha</span>}
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#c38b5f' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>E</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (department.length > 0 && project.length < 1 && team.length < 1) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#8c9298' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Departamentet:{' '}
                {department.map((element, index) => {
                  return index % 2 ? (
                    department.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {department.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#b4b8bc' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>D</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (department.length > 0 && project.length > 0 && team.length < 1) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#cda400' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Projektet:{' '}
                {project.map((element, index) => {
                  return index % 2 ? (
                    project.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {project.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#ffcc01' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>P</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#8c9298' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Departamentet:{' '}
                {department.map((element, index) => {
                  return index % 2 ? (
                    department.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {department.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#b4b8bc' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>D</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (department.length > 0 && project.length < 1 && team.length > 0) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#8c9298' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Departamentet:{' '}
                {department.map((element, index) => {
                  return index % 2 ? (
                    department.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {department.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#b4b8bc' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>D</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#c38b5f' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Ekipet:{' '}
                {team.map((element, index) => {
                  return index % 2 ? (
                    team.length - 1 === index ? (
                      element.first_name + ' ' + element.last_name
                    ) : (
                      element.first_name + ' ' + element.last_name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {team.length - 1 === index
                        ? element.first_name + ' ' + element.last_name
                        : element.first_name + ' ' + element.last_name + ', '}{' '}
                      <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#c38b5f' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>E</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (department.length < 1 && project.length > 0 && team.length > 0) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#cda400' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Projektet:{' '}
                {project.map((element, index) => {
                  return index % 2 ? (
                    project.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {project.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#ffcc01' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>P</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#c38b5f' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Ekipet:{' '}
                {team.map((element, index) => {
                  return index % 2 ? (
                    team.length - 1 === index ? (
                      element.first_name + ' ' + element.last_name
                    ) : (
                      element.first_name + ' ' + element.last_name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {team.length - 1 === index
                        ? element.first_name + ' ' + element.last_name
                        : element.first_name + ' ' + element.last_name + ', '}{' '}
                      <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#c38b5f' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>E</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (project.length > 0 && department.length < 1 && team.length < 1) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#cda400' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Projektet:{' '}
                {project.map((element, index) => {
                  return index % 2 ? (
                    project.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {project.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#ffcc01' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>P</p>
            </span>
          </Tooltip>
        </div>
      )
    } else if (team.length > 0 && department.length < 1 && project.length < 1) {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#c38b5f' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Ekipet:{' '}
                {team.map((element, index) => {
                  return index % 2 ? (
                    team.length - 1 === index ? (
                      element.first_name + ' ' + element.last_name
                    ) : (
                      element.first_name + ' ' + element.last_name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {team.length - 1 === index
                        ? element.first_name + ' ' + element.last_name
                        : element.first_name + ' ' + element.last_name + ', '}{' '}
                      <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#c38b5f' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>E</p>
            </span>
          </Tooltip>
        </div>
      )
    } else {
      return (
        <div>
          <Tooltip
            overlayStyle={{ backgroundColor: '#cda400' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Projektet:{' '}
                {project.map((element, index) => {
                  return index % 2 ? (
                    project.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {project.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#ffcc01' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>P</p>
            </span>
          </Tooltip>
          <Tooltip
            overlayStyle={{ backgroundColor: '#8c9298' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Departamentet:{' '}
                {department.map((element, index) => {
                  return index % 2 ? (
                    department.length - 1 === index ? (
                      element.name
                    ) : (
                      element.name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {department.length - 1 === index ? element.name : element.name + ', '} <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#b4b8bc' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>D</p>
            </span>
          </Tooltip>

          <Tooltip
            overlayStyle={{ backgroundColor: '#c38b5f' }}
            onClick={(e) => {
              e.preventDefault()
            }}
            placement='top'
            overlay={
              <span>
                Ekipet:{' '}
                {team.map((element, index) => {
                  return index % 2 ? (
                    team.length - 1 === index ? (
                      element.first_name + ' ' + element.last_name
                    ) : (
                      element.first_name + ' ' + element.last_name + ', '
                    )
                  ) : (
                    <span key={index}>
                      {team.length - 1 === index
                        ? element.first_name + ' ' + element.last_name
                        : element.first_name + ' ' + element.last_name + ', '}{' '}
                      <br />
                    </span>
                  )
                })}
              </span>
            }
          >
            <span className='fa-stack mr-2' style={{ cursor: 'default' }}>
              <em className='fa fa-circle fa-stack-2x' style={{ color: '#c38b5f' }} />
              <p className=' fa-stack-1x fa-inverse text-white'>E</p>
            </span>
          </Tooltip>
        </div>
      )
    }
  }

  _renderDelete(user_Level, userID, created_by_id, feed_id) {
    if (user_Level === 2 && userID === created_by_id) {
      return (
        <Button
          style={{ backgroundColor: '#f05050', color: 'white' }}
          onClick={(e) => {
            this._onDelete(e, feed_id)
          }}
        >
          Fshij
        </Button>
      )
    } else if (user_Level === 3 && userID === created_by_id) {
      return (
        <Button
          style={{ backgroundColor: '#f05050', color: 'white' }}
          onClick={(e) => {
            this._onDelete(e, feed_id)
          }}
        >
          Fshij
        </Button>
      )
    } else if (user_Level === 1 || user_Level === 5 || user_Level === 6) {
      return (
        <Button
          style={{ backgroundColor: '#f05050', color: 'white' }}
          onClick={(e) => {
            this._onDelete(e, feed_id)
          }}
        >
          Fshij
        </Button>
      )
    }
  }

  async componentDidMount() {
    this.setState({ videoWatched: false, isPlaying: false })
    window.addEventListener('message', this.handleVideoMessage)
    try {
      await axios.get(`/feeds/notread`).then((res) => {
        if (res.data.success && this.props.dataFeeds && res.data.data !== null) {
          this.setState({
            modal: true,
            data: res.data.data
          })
        } else {
        }
      })
    } catch (e) {
      console.log('error', e)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedIndex !== this.state.selectedIndex) {
      this.setState({ isPlaying: false, videoWatched: false })

      // Give the DOM time to update before trying to find the video
      setTimeout(() => {
        const videoElement = document.querySelector('video')
        if (videoElement) {
          this.videoRef = videoElement
          console.log('Video reference updated')
        }
      }, 300)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleVideoMessage)
  }

  handleVideoMessage = (event) => {
    if (event.data === 'videoEnded') {
      this.setState({ videoWatched: true })
    }
  }

  _renderImage(collection, feedId) {
    if (collection.length > 5) {
      return (
        <div>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            <img
              onClick={() =>
                this.setState({
                  modal: true,
                  isOpen: { ...this.state.isOpen, [feedId]: true },
                  photoIndex: 0
                })
              }
              className='img-thumbnail'
              style={{ objectFit: 'cover', width: '100%', maxHeight: 400 }}
              src={getAwsImage(collection[0])}
              alt='Demo'
            />
          </a>
          c
          <Row style={{ marginTop: 10 }}>
            {[...Array(3)].map((i, x) => {
              return (
                <Col key={x} xs={4} md={3}>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <Image
                      style={{ height: 120, objectFit: 'cover', width: '100%' }}
                      src={getAwsImage(collection[x + 1])}
                      thumbnail
                      onClick={() =>
                        this.setState({
                          modal: true,
                          isOpen: { ...this.state.isOpen, [feedId]: true },
                          photoIndex: x + 1
                        })
                      }
                    />
                  </a>
                </Col>
              )
            })}
            <Col xs={4} md={3}>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                <Image
                  style={{ height: 120, objectFit: 'cover', width: '100%' }}
                  src='/img/more_image.png'
                  thumbnail
                  onClick={() =>
                    this.setState({
                      modal: true,
                      isOpen: { ...this.state.isOpen, [feedId]: true },
                      photoIndex: 4
                    })
                  }
                />
              </a>
            </Col>
          </Row>
        </div>
      )
    } else {
      return (
        <div>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            <img
              onClick={() =>
                this.setState({
                  modal: true,
                  isOpen: { ...this.state.isOpen, [feedId]: true },
                  photoIndex: 0
                })
              }
              className='img-thumbnail'
              style={{ objectFit: 'cover', width: '100%', maxHeight: 400 }}
              src={getAwsImage(collection[0])}
              alt='Demo'
            />
          </a>

          <Row style={{ marginTop: 10 }}>
            {[...Array(collection.length - 1)].map((i, x) => {
              return (
                <Col key={x} xs={4} md={3}>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <Image
                      style={{ height: 120, objectFit: 'cover', width: '100%' }}
                      src={getAwsImage(collection[x + 1])}
                      thumbnail
                      onClick={() =>
                        this.setState({
                          modal: true,
                          isOpen: { ...this.state.isOpen, [feedId]: true },
                          photoIndex: x + 1
                        })
                      }
                    />
                  </a>
                </Col>
              )
            })}
          </Row>
        </div>
      )
    }
  }

  post(index) {
    const { isOpen, photoIndex } = this.state

    const userID = this.props.userID
    const user_level = this.props.user_level
    const data = this.state.data[index]
    const files = data.files
    const imageCollection = []

    let read = false
    if (index === 0 && this.state.data[index].read_by !== undefined) {
      for (const id of this.state.data[index].read_by.entries()) {
        if (id === userID) {
          read = true
        }
      }
      if (read) axios.patch(`/feeds/${this.state.data[index]._id}/read`).then((res) => {})
    }

    if (data.files && data.files[0] && data.files[0].mimetype.includes('image')) {
      files.map((file) => {
        if (file.mimetype.includes('image')) {
          imageCollection.push(file.url)
        }
      })
    }

    return (
      <div className='card b'>
        <div className='card-header'>
          {(user_level === 1 || user_level === 6 || user_level === 5) && (
            <div className='float-right'>
              <ul className='nav nav-pills align-items-center'>
                <div className='name-badge'>{this._badgeRender(data.target_department, data.target_project, data.target_team)}</div>
                {this._renderDelete(user_level, userID, data.created_by, data._id)}
              </ul>
            </div>
          )}
          <div className='media mt-0'>
            <img className='mr-2 img-thumbnail rounded-circle thumb32' src='img/blank_profile.png' />
            <div className='media-body'>
              <h4 className='m-0 text-bold'>{data.created_by.first_name + ' ' + data.created_by.last_name}</h4>
              <div className='text-sm text-muted'>{moment(data.posted_date).fromNow()}</div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='row' style={{ marginTop: -10 }}>
            <div className='col-xl-12 card-post'>
              <h4>{data.title}</h4>
              <div>{ReactHtmlParser(data.description, { transform: this.transform })}</div>
              {imageCollection.length > 0 ? this._renderImage(imageCollection, data._id) : null}
            </div>

            <div>
              {isOpen[data._id] && (
                <Lightbox
                  mainSrc={imageCollection[photoIndex]}
                  nextSrc={imageCollection[(photoIndex + 1) % imageCollection.length]}
                  prevSrc={imageCollection[(photoIndex + imageCollection.length - 1) % imageCollection.length]}
                  onCloseRequest={() =>
                    this.setState({
                      isOpen: { ...this.state.isOpen, [data._id]: false }
                    })
                  }
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + imageCollection.length - 1) % imageCollection.length
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % imageCollection.length
                    })
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div style={{ marginTop: -25 }}>
            <div className='float-left'>
              <ul className='nav nav-pills'>
                {files.length > 0 && (
                  <li className='nav-item'>
                    {files.map((file, index) => {
                      if (!file.mimetype.includes('image')) {
                        return (
                          <div key={index}>
                            <em className='fa fa-download' />
                            <a
                              key={index}
                              href={getAwsImage(file.url)}
                              style={{ marginLeft: 10 }}
                              target='_blank'
                              className='uploadDownload'
                            >
                              {file.name}
                            </a>
                          </div>
                        )
                      }
                    })}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const hasVideo = this.state.data[this.state.selectedIndex]?.description?.includes('<iframe')
    const isButtonDisabled = hasVideo && !this.state.videoWatched

    return (
      <Modal className='modal-lg modal-dialog-centered' isOpen={this.state.modal}>
        <ModalHeader>Postime te palexuara</ModalHeader>
        <ModalBody style={{ display: 'flex', paddingBottom: '1px' }}>
          <div className='col-lg-12'>
            <Row className='justify-content-center align-self-center'>
              <Col xl={12} style={{ paddingLeft: '22px', paddingRight: '15px' }}>
                {this.state.data !== undefined && this.state.data.length !== 0 && this.post(this.state.selectedIndex)}
              </Col>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter
          style={{
            justifyContent: 'center',
            paddingTop: '0px',
            borderTop: 'none',
            paddingBottom: '24px'
          }}
        >
          <div className='col-lg-12'>
            <Row className='justify-content-center align-self-center'>
              <Col xl={12} style={{ paddingLeft: '22px', paddingRight: '15px' }}>
                <Button color='info' className='btn-md btn-block btn-info' onClick={this.toggleRead} disabled={isButtonDisabled}>
                  <span
                    style={{
                      display: 'inline',
                      verticalAlign: 'middle',
                      float: 'left'
                    }}
                  >
                    <i className='fa fa-check' />
                  </span>
                  <span style={{ paddingRight: '15px' }}>{isButtonDisabled ? 'Shikoni videon deri në fund' : 'E lexova'}</span>
                </Button>
              </Col>
            </Row>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalFeed
